/* eslint-disable max-len */
const GlobalVariables = require("./globalVariables"); // Import the necessary module
const axios = require("axios");

class Salesforce {
  static getEndpoints() {
    return {
      connectToSalesforce: "/salesforce/salesforce/connect",
      getSalesforceObjects: "/salesforce/salesforce/getSalesforceObjects",
      getObjectProperties: "/salesforce/salesforce/getObjectProperties",
      addToSalesforce: "/salesforce/salesforce/addObject",
      getAllDealsPipelines: "/salesforce/salesforce/getAllDealsPipelines",
      getEntities: "/salesforce/salesforce/entities",
      getImportedEmailThread: "/salesforce/salesforce/imported-email-thread",
    };
  }

  static async connectToSalesforce(code, email) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() +
      Salesforce.getEndpoints().connectToSalesforce;
    // eslint-disable-next-line max-len
    console.log(`${ENDPOINT}?code=${code}&email=${email}`);
    return await fetch(`${ENDPOINT}?code=${code}&email=${email}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          return { success: true, response: data };
        } else {
          return { success: false, reason: "internal server error" };
        }
      })
      .catch((error) => {
        console.error(error);
        return { success: false, reason: "internal server error" };
      });
  }

  static async getObjectProperties(email, objectType) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() + Salesforce.getEndpoints().getEntities;
    // eslint-disable-next-line max-len
    return await fetch(`${ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        crmField: objectType,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          return { success: true, properties: data.salesforceObjects };
        } else {
          return { success: false, reason: "internal server error" };
        }
      })
      .catch((error) => {
        console.error(error);
        return { success: false, reason: "internal server error" };
      });
  }

  static async getEntities(email) {
    const url =
      GlobalVariables.getAPIURL() + Salesforce.getEndpoints().getEntities;
    const response = await axios.post(
      url,
      { email },
      {
        timeout: 30000,
      }
    );
    return {
      accounts: response.data.accounts,
      contacts: response.data.contacts,
    };
  }

  static async getImportedThreadId(email, threadId) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() +
      Salesforce.getEndpoints().getImportedEmailThread;
    // eslint-disable-next-line max-len
    return await fetch(`${ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        threadId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  static async addToSalesforce(email, properties, threadId) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() + Salesforce.getEndpoints().addToSalesforce;
    // eslint-disable-next-line max-len
    return await fetch(`${ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        properties: properties,
        threadId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          return { success: true, urls: data.salesforceObjects };
        } else {
          return { success: false, error: data.error };
        }
      })
      .catch((error) => {
        console.error(error);
        return { success: false, reason: "internal server error" };
      });
  }
}

module.exports = Salesforce;
