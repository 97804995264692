/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable space-before-function-paren */
import { useState, useEffect, useRef, useMemo } from "react";
import EmailActionNavigationBreadCrumbs from "../../components/EmailActionNavigationBreadCrumbs";
import {
  Autocomplete,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Alert,
} from "@mui/material";
import AddyLoading from "./AddyLoading";
import styles from "./FormSalesforce.module.css";
import ConfidenceLevel from "../../components/ConfidenceLevel";
import Tooltip from "../../components/Tooltip";
import ChatModel from "../../models/chatModel";
import User from "../../models/user";
import useLocalStorage from "../../hooks/localStorage";
import * as GlobalVariables from "../../models/globalVariables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import AddyWebSocket from "../../models/addyWebSocket";
import { useLocation, useNavigate } from "react-router-dom";
import Salesforce from "../../models/salesforce";
import { getErrorMessage } from "../../helpers/getErrorMessage";

const FormSalesforce = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedIntegrationStorage, setSelectedIntegrationStorage] =
    useLocalStorage("crmIntegration", {});

  const tableDropDownRef = useRef(null);
  const tableDropdownListRef = useRef(null);
  // User data passed from chrome extension

  let data = {};
  let emailThread = "";
  let attachmentListBase64 = [];
  if (location?.state?.data) {
    data = location.state.data;
  }
  if (location?.state?.emailThread) {
    emailThread = location.state.emailThread;
  }
  if (location?.state?.attachmentListBase64) {
    attachmentListBase64 = location.state.attachmentListBase64;
  }
  const uid = data?.uid || null;
  const email = data?.email || null;
  const clientID = data?.clientID || null;

  const name = data?.name || "You";

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [loadingAddToHubspot, setLoadingAddToHubspot] = useState(false);
  const [loadingConfirmData, setLoadingConfirmData] = useState(false);
  // Step 1 = confirm data, step 2 = map column headers, step 3 = congrats scren
  const [selectedCRMTable, setSelectedCRMTable] = useState({});
  const [connectToHubspotNeeded, setConnectToHubspotNeeded] = useState(false);
  const [renderData, setRenderData] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [integration, setIntegration] = useState([]);
  const [showConfidenceScoreTooltip, setShowConfidenceScoreTooltip] =
    useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [currentlyFocussedFields, setCurrentlyFocussedFields] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [startupErrorMesssage, setStartupErrorMessage] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [createdOpportunityUrl, setCreatedOpportunityUrl] = useState("");
  const [accountName, setAccountName] = useState("");
  const [contactName, setContactName] = useState("");
  const [alreadyImportedOpportunity, setAlreadyImportedOpportunity] =
    useState();

  const accountsOptions = useMemo(() => {
    return accounts.map((c) => ({
      label: c.Name,
      key: c.Id,
      value: c.Id,
    }));
  }, [accounts]);

  const contactsInAccountOptions = useMemo(() => {
    return (
      contacts
        ?.filter((c) => c.AccountId === selectedAccount)
        ?.map((c) => ({
          label: c.Name,
          key: c.Id,
          value: c.Id,
        })) || []
    );
  }, [contacts, selectedAccount]);

  const windowListenForPostMessage = () => {
    window.addEventListener("message", (event) => {
      const origin = event.origin || event.originalEvent.origin;
      const data = event.data;

      if (data && data.key) {
        const action = data.key;
        const value = data.value;
        switch (action) {
          case "emailThread":
            emailThread = value;
            break;
          case "attachmentListBase64":
            attachmentListBase64 = value;
            break;
          default:
            break;
        }
      }
    });
  };

  async function callLLM(
    tableV,
    first,
    sampleRenderDataContacts,
    sampleRenderDataDeals = [],
    dealsPipeline = [],
    crmIntegrationF
  ) {
    try {
      if (first) setLoading(true);
      const response = await ChatModel.chat(
        email,
        name,
        emailThread,
        "salesforce",
        attachmentListBase64,
        dealsPipeline
      );

      setAccountName(response.chatResponse["Account:Name"] || "");
      setContactName(response.chatResponse["Contact:Name"] || "");
      for (let i = 0; i < sampleRenderDataContacts.length; i++) {
        const confidence =
          "Confidence:" + sampleRenderDataContacts[i].attribute;
        sampleRenderDataContacts[i].confidence =
          response.chatResponse[confidence];
        if (sampleRenderDataContacts[i].type === "dropdown") {
          sampleRenderDataContacts[i].selectedIndex = sampleRenderDataContacts[
            i
          ].value.indexOf(
            response.chatResponse[sampleRenderDataContacts[i].attribute]
          );
        } else if (sampleRenderDataContacts[i].type === "singleLineText") {
          sampleRenderDataContacts[i].value =
            response.chatResponse[sampleRenderDataContacts[i].attribute];
        }
      }
      for (let i = 0; i < sampleRenderDataDeals.length; i++) {
        const confidence = "Confidence:" + sampleRenderDataDeals[i].attribute;
        sampleRenderDataDeals[i].confidence = response.chatResponse[confidence];
        if (sampleRenderDataDeals[i].type === "dropdown") {
          if (sampleRenderDataDeals[i].attribute === "dealPipeline") {
            const labelArray = dealsPipeline.dealsPipelines.map(
              (item) => item.label
            );
            sampleRenderDataDeals[i].value = labelArray;
            sampleRenderDataDeals[i].selectedIndex = labelArray.indexOf(
              response.chatResponse[sampleRenderDataDeals[i].attribute]
            );
          } else {
            if (
              sampleRenderDataDeals[i].attribute === "dealStage" &&
              crmIntegrationF === "hubspot"
            ) {
              const stageNamesArray = [];
              dealsPipeline.dealsPipelines.forEach((item) => {
                item.stages.forEach((stage) =>
                  stageNamesArray.push(stage.label)
                );
              });
              sampleRenderDataDeals[i].value = stageNamesArray;
              sampleRenderDataDeals[i].selectedIndex = stageNamesArray.indexOf(
                response.chatResponse[sampleRenderDataDeals[i].attribute]
              );
            } else {
              sampleRenderDataDeals[i].selectedIndex = sampleRenderDataDeals[
                i
              ].value.indexOf(
                response.chatResponse[sampleRenderDataDeals[i].attribute]
              );
            }
          }
        } else if (sampleRenderDataDeals[i].type === "singleLineText") {
          sampleRenderDataDeals[i].value =
            response.chatResponse[sampleRenderDataDeals[i].attribute];
        }
      }

      // const renderDataTemp = renderData;
      // renderDataTemp["contacts"] = sampleRenderDataContacts;
      // renderDataTemp["deals"] = sampleRenderDataDeals;
      // setRenderData(renderDataTemp);

      await populateUsersFromDomain(
        sampleRenderDataContacts,
        sampleRenderDataDeals,
        renderData,
        first,
        tableV
      );

      setErrorMessage("");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setStartupErrorMessage("Error extracting information from email");
    }
  }

  async function populateUsersFromDomain(
    sampleRenderDataContacts,
    sampleRenderDataDeals,
    renderDataTemp,
    first,
    table
  ) {
    if (table !== "opportunities") {
      const userDomains = await getUsersByDomain();
      if (userDomains.length > 0) {
        const userMails = userDomains.map((user) => user.email);
        const userNames = userDomains.map((user) => user.name);

        sampleRenderDataContacts[
          sampleRenderDataContacts.length - 1
        ].confidence = 10;
        sampleRenderDataContacts[sampleRenderDataContacts.length - 1].value =
          userNames;
        sampleRenderDataContacts[
          sampleRenderDataContacts.length - 1
        ].selectedIndex = userMails.indexOf(email);

        sampleRenderDataDeals[sampleRenderDataDeals.length - 1].confidence = 10;
        sampleRenderDataDeals[sampleRenderDataDeals.length - 1].value =
          userNames;
        sampleRenderDataDeals[sampleRenderDataDeals.length - 1].selectedIndex =
          userMails.indexOf(email);
      }
      renderDataTemp["contacts"] = sampleRenderDataContacts;
      renderDataTemp["deals"] = sampleRenderDataDeals;
      setRenderData(renderDataTemp);
      if (first) {
        initializeFormData(sampleRenderDataContacts);
      }
    } else {
      renderDataTemp["opportunities"] = sampleRenderDataDeals;
      renderDataTemp["contacts"] = sampleRenderDataContacts;

      // renderDataTemp["contacts"] = await populateContactsFromSalesforce(
      //     GlobalVariables.getCorrectRenderData(
      //         "contacts-salesforce"
      //     )
      // );

      setRenderData(renderDataTemp);
      if (first) {
        initializeFormData(sampleRenderDataDeals);
      }
    }
  }

  async function populateView(integration) {
    try {
      await populateFromSalesforce();
    } catch (error) {
      console.error(error);
      setSessionExpired(true);
      setConnectToHubspotNeeded(true);
    }
  }

  // Add this function inside your component
  const handleChange = (attribute, type) => (event) => {
    if (type === "singleLineText" || type === "dropdown") {
      setFormData((prev) => ({
        ...prev,
        [attribute]: event.target.value,
      }));
      let renderDataTemp = renderData[selectedCRMTable.name];
      if (renderDataTemp && renderDataTemp.length > 0) {
        renderDataTemp = renderDataTemp.map((field) => {
          if (field.attribute === attribute) {
            if (type === "dropdown") {
              field.selectedIndex = field.value.indexOf(event.target.value);
            } else {
              field.value = event.target.value;
            }
          }
          return field;
        });
      }
      setRenderData((prev) => ({
        ...prev,
        [selectedCRMTable.name]: renderDataTemp,
      }));
    }
  };

  const clearHighlightedText = (id) => {
    // Send clear action to client webpage via WebSocket
    if (id) {
      AddyWebSocket.retrySendDataToWebsocket(
        "",
        `${id}-webpage`,
        "clear-all-highlighted-text",
        2
      );
    }
  };

  const highlightTextOnClient = (id, text) => {
    // Send highlight action to client webpage via WebSocket
    if (id) {
      AddyWebSocket.retrySendDataToWebsocket(
        text,
        `${id}-webpage`,
        "highlight-selected-field",
        2
      );
    }
  };

  useEffect(() => {
    windowListenForPostMessage();
    getUserData();

    // eslint-disable-next-line no-undef
    document.addEventListener("click", handleClickOutside);
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Use effect on focussed fields change
  useEffect(() => {
    // If there are no focussed fields, clear all highlighted text
    if (currentlyFocussedFields.length === 0) {
      clearHighlightedText(clientID);
    } else {
      // If there are focussed fields, highlight the text
      for (const field of currentlyFocussedFields) {
        highlightTextOnClient(clientID, field.value);
      }
    }
  }, [currentlyFocussedFields]);

  useEffect(() => {
    Salesforce.getEntities(email)
      .then((entities) => {
        setAccounts(entities.accounts);
        setContacts(entities.contacts);
      })
      .catch((e) => {
        const message = getErrorMessage(e);
        console.error(e);
        if (message.includes("expired access/refresh token")) {
          setSessionExpired(true);
        } else {
          setStartupErrorMessage(
            "Error fetching the accounts and contacts from Salesforce"
          );
        }
      });
  }, [email]);

  useEffect(() => {
    if (email && data.threadId) {
      Salesforce.getImportedThreadId(email, data.threadId)
        .then((imported) => {
          setAlreadyImportedOpportunity(imported ? imported.existing : null);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [data]);

  useEffect(() => {
    if (accounts.length && !selectedAccount && accountName) {
      const foundAccount = accounts.find(
        (a) => a.Name.toLowerCase().trim() === accountName.toLowerCase().trim()
      );
      if (foundAccount) {
        setSelectedAccount(foundAccount.Id);
      }
    }
  }, [accounts, selectedAccount, accountName]);

  useEffect(() => {
    if (contacts.length && !selectedContact && selectedAccount && contactName) {
      const availableContacts = contacts.filter(
        (c) => c.AccountId === selectedAccount
      );
      console.log(availableContacts, contactName);
      const foundContact = availableContacts.find(
        (a) => a.Name.toLowerCase().trim() === contactName.toLowerCase().trim()
      );
      if (foundContact) {
        setSelectedContact(foundContact.Id);
      }
    }
  }, [contacts, selectedContact, accounts, selectedAccount, contactName]);

  const getUserData = async () => {
    const userInfo = await User.getUserInfo(email);
    let integrations = [];
    if (userInfo && userInfo.success && userInfo.response) {
      if (userInfo.response.hubspot) {
        integrations.push({
          integration: GlobalVariables.integrationType.HUBSPOT,
          email: email,
        });
      }
      if (userInfo.response.salesforce) {
        integrations.push({
          integration: GlobalVariables.integrationType.SALESFORCE,
          email: email,
        });
      }
    }

    setIntegration(integrations);
    if (integrations.length >= 1) {
      if (
        selectedIntegrationStorage &&
        selectedIntegrationStorage.integration &&
        integrations.find(
          (integration) =>
            integration.integration === selectedIntegrationStorage.integration
        )
      ) {
        setSelectedIntegration(selectedIntegrationStorage);
        populateView(selectedIntegrationStorage.integration);
      } else {
        setSelectedIntegration(integrations[0]);
        populateView(integrations[0].integration);
      }
      setConnectToHubspotNeeded(false);
    } else {
      setSessionExpired(true);
      setConnectToHubspotNeeded(true);
      setLoading(false);
    }
  };

  const initializeFormData = (renderData) => {
    for (const field of renderData) {
      let value = field.value;
      if (field.type === "dropdown") {
        value = field.value[field.selectedIndex];
      }
      setFormData((prev) => ({
        ...prev,
        [field.attribute]: value,
      }));
    }
  };

  const writeEmail = async () => {
    navigate("/email/home", {
      state: {
        data: data,
        emailThread: emailThread,
        attachmentListBase64: attachmentListBase64,
        writeLoan: true,
        uid: uid,
        loanInformation: renderData,
      },
    });
  };

  const handleFocus = (attribute, type, value) => (event) => {
    const focusedField = {
      attribute: attribute,
      type: type,
      value: value,
    };
    // If this field is not already in the currently focussed fields array
    // Add it to the array
    if (
      !currentlyFocussedFields.find((field) => field.attribute === attribute)
    ) {
      setCurrentlyFocussedFields((prev) => [...prev, focusedField]);
    }
  };

  // Remove the field from the currently focussed fields array
  const handleBlur = (attribute) => (event) => {
    // Remove the field from the currently focussed fields array
    setCurrentlyFocussedFields((prev) =>
      prev.filter((field) => field.attribute !== attribute)
    );
  };

  const renderInputField = (field) => {
    switch (field.type) {
      case "singleLineText":
        return (
          <div className={styles.h2HeadingGroup}>
            <div className={styles.h2Heading2}>{field.display}</div>

            <div className={styles.scoreFrame}>
              <div className={styles.textInputContainer}>
                <input
                  className={styles.textInput}
                  value={formData[field.attribute]}
                  onFocus={handleFocus(
                    field.attribute,
                    field.type,
                    formData[field.attribute]
                  )}
                  onBlur={handleBlur(field.attribute)}
                  onChange={handleChange(field.attribute, field.type)}
                  placeholder={field.placeholder || ""}
                  type={
                    field.inputType === "percentage"
                      ? "number"
                      : field.inputType === "date"
                      ? "date"
                      : undefined
                  }
                />
              </div>

              <div>
                <ConfidenceLevel score={field.confidence} />
              </div>
            </div>
          </div>
        );
      case "dropdown":
        return (
          <div className={styles.h2HeadingParent2}>
            <div className={styles.dropDownInputContainer}>
              <div className={styles.h2Heading2}>{field.display}</div>
              <div className={styles.scoreFrame}>
                <FormControl
                  className={styles.button5}
                  variant="standard"
                  sx={{
                    borderColor: "#eff1f6",
                    borderStyle: "SOLID",
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    width: "100%",
                    height: "32px",
                    m: 0,
                    p: 0,
                    "& .MuiInputBase-root": {
                      m: 0,
                      p: 0,
                      minHeight: "32px",
                      justifyContent: "center",
                      display: "inline-flex",
                    },
                    "& .MuiInputLabel-root": {
                      m: 0,
                      p: 0,
                      minHeight: "32px",
                      display: "inline-flex",
                    },
                    "& .MuiMenuItem-root": {
                      m: 0,
                      p: 0,
                      height: "32px",
                      display: "inline-flex",
                    },
                    "& .MuiSelect-select": {
                      m: 0,
                      p: 0,
                      height: "32px",
                      alignItems: "center",
                      display: "inline-flex",
                    },
                    "& .MuiInput-input": { m: 0, p: 0 },
                    "& .MuiInputBase-input": {
                      color: "#0d0d25",
                      fontSize: 14,
                      fontWeight: "Medium",
                      fontFamily: "Inter",
                      textAlign: "left",
                      p: "0 !important",
                      marginLeft: "12px",
                    },
                  }}
                >
                  <InputLabel color="secondary" />
                  <Select
                    color="secondary"
                    disableUnderline
                    displayEmpty
                    defaultValue={formData[field.attribute]}
                    value={formData[field.attribute]}
                    onChange={handleChange(field.attribute, field.type)}
                  >
                    {field.value.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>

                <div>
                  <ConfidenceLevel score={field.confidence} />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleConfidenceTooltipCloseClick = () => {
    setShowConfidenceScoreTooltip(false);
  };

  async function populateFromSalesforce() {
    const response = { success: true, objects: ["opportunities", "contacts"] };
    let tableV = "Opportunities";
    const tables = response.objects.map((table) => {
      return {
        name: table,
      };
    });
    tableV = tables[0].name;
    setSelectedCRMTable({
      name: tables[0].name,
      title: "Store data using this table", // use this static title
    });

    for (let i = 0; i < 50; i++) {
      if (emailThread !== "") {
        break;
      }
      await new Promise((r) => setTimeout(r, 200));
    }
    if (emailThread === "") {
      return setStartupErrorMessage("Error getting email content");
    }

    const sampleOppurtunityRenderDataDeals =
      GlobalVariables.getCorrectRenderData("salesforce");

    await callLLM(
      tableV,
      true,
      sampleOppurtunityRenderDataDeals,
      sampleOppurtunityRenderDataDeals,
      [],
      "salesforce"
    );
  }

  async function getUsersByDomain() {
    const domain = email.split("@")[1];
    let userDomains = [];
    if (domain !== "gmail.com") {
      userDomains = await User.getUsersByDomain(domain);
      if (userDomains && userDomains.success) {
        userDomains = userDomains.response.map((user) => {
          return {
            email: user.email,
            name:
              user.name !== undefined
                ? user.name
                : user.userData.name !== undefined
                ? user.userData.name
                : user.email,
          };
        });

        const user = userDomains.filter((user) => user.email === email);
      }
    }
    return userDomains;
  }

  const addToSalesforceClick = async () => {
    const fields = renderData.opportunities.map((o) => ({
      attribute: o.attribute,
      value: o.value,
    }));
    fields.push({
      attribute: "Account:Id",
      value: selectedAccount,
    });
    fields.push({
      attribute: "Contact:Id",
      value: selectedContact,
    });
    fields.push({
      // Every application must start in the scenario stage
      attribute: "Opportunity:StageName",
      value: "Scenario Approved",
    });
    fields.push({
      attribute: "Opportunity:Interest_Rate_Higher_Rate__c",
      value: "",
    });
    if (!fields.find((f) => f.attribute === "Account:Id").value)
      throw new Error(`Please select an Account (Brokerage)`);
    if (!fields.find((f) => f.attribute === "Contact:Id").value)
      throw new Error(`Please select a Contact (Broker)`);
    if (!fields.find((f) => f.attribute === "Opportunity:Name").value)
      throw new Error(`Please inform a Deal name`);
    if (!fields.find((f) => f.attribute === "Opportunity:CloseDate").value)
      throw new Error(`Please select a Settlement date`);
    return await Salesforce.addToSalesforce(email, fields, data.threadId);
  };

  // Event handler for click events
  const handleClickOutside = (event) => {
    // If user clicks outside or inside of dropdown ref
    // Close the dropdown
    if (
      tableDropDownRef.current &&
      !tableDropDownRef.current.contains(event.target) &&
      tableDropdownListRef.current &&
      !tableDropdownListRef.current.contains(event.target)
    ) {
      if (
        !(
          tableDropdownListRef.current &&
          !tableDropdownListRef.current.contains(event.target)
        )
      ) {
      }
    }
  };

  const onAddToIntegrationClick = async () => {
    setLoadingAddToHubspot(true);
    setSuccess(false);
    setErrorMessage("");
    try {
      const response = await addToSalesforceClick();
      console.log("RESPONSE: ", response);
      if (response.success) {
        setCreatedOpportunityUrl(response.urls.opportunity);
        setSuccess(true);
      } else {
        throw new Error(response.error);
      }
    } catch (e) {
      console.error("Error importing to Salesforce:", e);
      if (e.message.includes("expired access/refresh token")) {
        setSessionExpired(true);
      } else {
        setErrorMessage(e.message || "Error importing data to Salesforce.");
      }
    } finally {
      setLoadingAddToHubspot(false);
    }
  };

  const toastify = (message, success) => {
    if (success) {
      toast.success(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  function renderVariableContent() {
    if (sessionExpired) {
      return (
        <div
          style={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            paddingTop: "70px",
          }}
        >
          <div className={styles.connectToHubspotText}>
            Your Salesforce connection has expired. Please reconnect to continue
            using.
          </div>

          <LoadingButton
            className={styles.button10}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#f7f8fc",
              fontSize: "16",
              background:
                "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
              borderRadius: "8px",
              "&:hover": {
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
              },
              "&:disabled": {
                background: "lightgrey",
                color: "white",
              },
              height: 32,
            }}
            onClick={() => {
              window.parent.postMessage("reconnect", "*");
            }}
          >
            Reconnect to Salesforce
          </LoadingButton>
        </div>
      );
    } else if (startupErrorMesssage) {
      return (
        <div
          className="addy-custom-scroll"
          style={{
            width: "100%",
            flex: 1,
            paddingTop: "70px",
          }}
        >
          <div
            className={styles.secondaryText}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: ".25rem 1rem",
              gap: "1rem",
            }}
          >
            <b className={styles.h2Heading1}>Error</b>
            <div>{startupErrorMesssage}</div>
            <LoadingButton
              className={styles.button10}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#f7f8fc",
                fontSize: "16",
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                borderRadius: "8px",
                "&:hover": {
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                },
                "&:disabled": {
                  background: "lightgrey",
                  color: "white",
                },
                height: 32,
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Try again
            </LoadingButton>
          </div>
        </div>
      );
    } else if (errorMessage) {
      return (
        <div
          className="addy-custom-scroll"
          style={{
            width: "100%",
            paddingTop: "70px",
            flex: 1,
          }}
        >
          <div
            className={styles.secondaryText}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: ".25rem 1rem",
              gap: "1rem",
            }}
          >
            <b className={styles.h2Heading1}>Error</b>
            <div>{errorMessage}</div>
            <LoadingButton
              className={styles.button10}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#f7f8fc",
                fontSize: "16",
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                borderRadius: "8px",
                "&:hover": {
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                },
                "&:disabled": {
                  background: "lightgrey",
                  color: "white",
                },
                height: 32,
              }}
              onClick={() => {
                setErrorMessage("");
              }}
            >
              Try again
            </LoadingButton>
          </div>
        </div>
      );
    } else if (connectToHubspotNeeded) {
      return (
        <div className={styles.connectToHubspotText}>
          Connect to HubSpot or Salesforce on the extension to proceed
        </div>
      );
    } else if (loading) {
      return <AddyLoading />;
    } else if (alreadyImportedOpportunity) {
      return (
        <div
          style={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            paddingTop: "70px",
          }}
        >
          <div className={styles.connectToHubspotText}>
            This deal has already been imported to Salesforce with the name{" "}
            <a
              href={alreadyImportedOpportunity.opportunityUrl}
              target="_blank"
              className={styles.customLink}
            >
              {alreadyImportedOpportunity.opportunityName || "Deal"}
            </a>
            .
          </div>

          <LoadingButton
            className={styles.button10}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#f7f8fc",
              fontSize: "16",
              background:
                "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
              borderRadius: "8px",
              "&:hover": {
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
              },
              "&:disabled": {
                background: "lightgrey",
                color: "white",
              },
              height: 32,
            }}
            onClick={() => {
              setAlreadyImportedOpportunity(null);
            }}
          >
            Import again
          </LoadingButton>
        </div>
      );
    } else if (success) {
      return (
        <div
          className="addy-custom-scroll"
          style={{
            width: "100%",
            flex: 1,
            paddingTop: "70px",
          }}
        >
          <div className={styles.successContainer}>
            <b className={styles.h2Heading1}>Congratulations!</b>
            <div className={styles.descriptionText}>
              You've successfully imported the deal to Salesforce
            </div>
            <a
              href={createdOpportunityUrl}
              target="_blank"
              className={styles.customLink}
            >
              Open deal
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div
            className="addy-custom-scroll"
            style={{
              width: "100%",
              flex: 1,
              padding: "70px 0 0 0",
            }}
          >
            <section className={styles.expandableInstanceWrapper}>
              <div className={styles.expandableInstance}>
                {/* <OpenExternalSiteButton /> */}
                <div className={styles.expandableInstanceInner}>
                  <form className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                      <div className={styles.frameWrapper}>
                        <div className={styles.h2HeadingParent}>
                          <div className={styles.formHeaderDiv}>
                            <b className={styles.h2Heading1}>Fields</b>
                            <div className={styles.frameParentScore}>
                              <div
                                className={styles.frameGroupScore}
                                onClick={() =>
                                  setShowConfidenceScoreTooltip(true)
                                }
                              >
                                <div className={styles.h2HeadingWrapperScore}>
                                  <div className={styles.h2HeadingScore}>
                                    Score
                                  </div>
                                  {showConfidenceScoreTooltip && (
                                    <Tooltip
                                      tooltipCloseClick={
                                        handleConfidenceTooltipCloseClick
                                      }
                                    />
                                  )}
                                </div>
                                <img
                                  className={styles.infoIconScore}
                                  loading="lazy"
                                  alt=""
                                  src="/info.svg"
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles.h2HeadingParent2}>
                            <div className={styles.dropDownInputContainer}>
                              <div className={styles.h2Heading2}>
                                Account (Brokerage)
                              </div>
                              <div className={styles.scoreFrame}>
                                <FormControl
                                  className={styles.button5}
                                  variant="standard"
                                  sx={{
                                    borderColor: "#eff1f6",
                                    borderStyle: "SOLID",
                                    borderTopWidth: "1px",
                                    borderRightWidth: "1px",
                                    borderBottomWidth: "1px",
                                    borderLeftWidth: "1px",
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                    width: "100%",
                                    height: "32px",
                                    m: 0,
                                    p: 0,
                                    "& .MuiInputBase-root": {
                                      m: 0,
                                      p: 0,
                                      minHeight: "32px",
                                      justifyContent: "center",
                                      display: "inline-flex",
                                    },
                                    "& .MuiInputLabel-root": {
                                      m: 0,
                                      p: 0,
                                      minHeight: "32px",
                                      display: "inline-flex",
                                    },
                                    "& .MuiMenuItem-root": {
                                      m: 0,
                                      p: 0,
                                      height: "32px",
                                      display: "inline-flex",
                                    },
                                    "& .MuiSelect-select": {
                                      m: 0,
                                      p: 0,
                                      height: "32px",
                                      alignItems: "center",
                                      display: "inline-flex",
                                    },
                                    "& .MuiInput-input": { m: 0, p: 0 },
                                    "& .MuiInputBase-input": {
                                      color: "#0d0d25",
                                      fontSize: 14,
                                      fontWeight: "Medium",
                                      fontFamily: "Inter",
                                      textAlign: "left",
                                      p: "0 !important",
                                      marginLeft: "12px",
                                    },
                                  }}
                                >
                                  <InputLabel color="secondary" />
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    options={accountsOptions}
                                    filterOptions={(
                                      options,
                                      { inputValue }
                                    ) => {
                                      if (
                                        !inputValue ||
                                        inputValue.length < 2
                                      ) {
                                        return [];
                                      }
                                      return options.filter((o) =>
                                        o.label
                                          .toLowerCase()
                                          .includes(inputValue.toLowerCase())
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        variant="standard"
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true, // <== added this
                                        }}
                                      />
                                    )}
                                    value={
                                      accountsOptions.find(
                                        (o) => o.value === selectedAccount
                                      ) || ""
                                    }
                                    noOptionsText="Start typing to search..."
                                    onChange={(e, newValue) => {
                                      setSelectedAccount(newValue?.key || "");
                                      setSelectedContact("");
                                    }}
                                    color="secondary"
                                    disableUnderline
                                  />
                                  <FormHelperText />
                                </FormControl>
                              </div>
                            </div>
                          </div>

                          <div className={styles.h2HeadingParent2}>
                            <div className={styles.dropDownInputContainer}>
                              <div className={styles.h2Heading2}>
                                Contact (Broker)
                              </div>
                              <div className={styles.scoreFrame}>
                                <FormControl
                                  className={styles.button5}
                                  variant="standard"
                                  sx={{
                                    borderColor: "#eff1f6",
                                    borderStyle: "SOLID",
                                    borderTopWidth: "1px",
                                    borderRightWidth: "1px",
                                    borderBottomWidth: "1px",
                                    borderLeftWidth: "1px",
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                    width: "100%",
                                    height: "32px",
                                    m: 0,
                                    p: 0,
                                    "& .MuiInputBase-root": {
                                      m: 0,
                                      p: 0,
                                      minHeight: "32px",
                                      justifyContent: "center",
                                      display: "inline-flex",
                                    },
                                    "& .MuiInputLabel-root": {
                                      m: 0,
                                      p: 0,
                                      minHeight: "32px",
                                      display: "inline-flex",
                                    },
                                    "& .MuiMenuItem-root": {
                                      m: 0,
                                      p: 0,
                                      height: "32px",
                                      display: "inline-flex",
                                    },
                                    "& .MuiSelect-select": {
                                      m: 0,
                                      p: 0,
                                      height: "32px",
                                      alignItems: "center",
                                      display: "inline-flex",
                                    },
                                    "& .MuiInput-input": { m: 0, p: 0 },
                                    "& .MuiInputBase-input": {
                                      color: "#0d0d25",
                                      fontSize: 14,
                                      fontWeight: "Medium",
                                      fontFamily: "Inter",
                                      textAlign: "left",
                                      p: "0 !important",
                                      marginLeft: "12px",
                                    },
                                  }}
                                >
                                  <InputLabel color="secondary" />
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    options={contactsInAccountOptions}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        variant="standard"
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true, // <== added this
                                        }}
                                      />
                                    )}
                                    value={
                                      contactsInAccountOptions.find(
                                        (o) => o.key === selectedContact
                                      ) || ""
                                    }
                                    onChange={(e, newValue) => {
                                      setSelectedContact(newValue?.key || "");
                                    }}
                                    color="secondary"
                                    disableUnderline
                                    displayEmpty
                                  />
                                  <FormHelperText />
                                </FormControl>
                              </div>
                            </div>
                          </div>

                          {renderData[selectedCRMTable.name]?.map(
                            (field, index) => (
                              <div
                                className={styles.fieldContainer}
                                key={index}
                              >
                                {renderInputField(field)}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
          <div className={styles.bottomSection}>
            <LoadingButton
              disabled={connectToHubspotNeeded || loading || errorMessage}
              className={styles.button10}
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              startIcon={
                !loadingAddToHubspot ? (
                  <img width="18px" height="18px" src="/plus_white.svg" />
                ) : (
                  ""
                )
              }
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#f7f8fc",
                fontSize: "16",
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                borderRadius: "8px",
                "&:hover": {
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                },
                "&:disabled": {
                  background: "lightgrey",
                  color: "white",
                },
                height: 32,
              }}
              onClick={() => {
                onAddToIntegrationClick();
              }}
              loading={loadingAddToHubspot}
            >
              {!loadingConfirmData ? "Add to Salesforce" : ""}
            </LoadingButton>
            <LoadingButton
              onClick={() => writeEmail()}
              loading={loadingConfirmData}
              disabled={connectToHubspotNeeded || loading || errorMessage}
              className={styles.button10}
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              startIcon={
                !loadingConfirmData ? (
                  <img width="18px" height="18px" src="/electric_bolt_AI.svg" />
                ) : (
                  ""
                )
              }
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#ab7ff1",
                fontSize: "16",
                background: "white",
                borderRadius: "8px",
                "&:hover": {
                  background: "#ede2ff",
                },
                "&:disabled": {
                  background: "lightgrey",
                  color: "white",
                },
                height: 32,
              }}
            >
              {!loadingConfirmData ? "Ask for more info" : ""}
            </LoadingButton>
          </div>
        </>
      );
    }
  }

  return (
    <div className={styles.commercial}>
      <main className={styles.topBarNewMessageParent}>
        <div
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!!integration && integration.length >= 1 ? (
            <header className={styles.topBarNewMessage}>
              <FormControl
                className={styles.button}
                variant="standard"
                sx={{
                  borderColor: "#eff1f6",
                  borderStyle: "SOLID",
                  borderTopWidth: "1px",
                  borderRightWidth: "1px",
                  borderBottomWidth: "1px",
                  borderLeftWidth: "1px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  width: "59.34065934065934%",
                  height: "32px",
                  m: 0,
                  p: 0,
                  "& .MuiInputBase-root": {
                    m: 0,
                    p: 0,
                    minHeight: "32px",
                    justifyContent: "center",
                    display: "inline-flex",
                  },
                  "& .MuiInputLabel-root": {
                    m: 0,
                    p: 0,
                    minHeight: "32px",
                    display: "inline-flex",
                  },
                  "& .MuiMenuItem-root": {
                    m: 0,
                    p: 0,
                    height: "32px",
                    display: "inline-flex",
                  },
                  "& .MuiSelect-select": {
                    m: 0,
                    p: 0,
                    height: "32px",
                    alignItems: "center",
                    display: "inline-flex",
                  },
                  "& .MuiInput-input": { m: 0, p: 0 },
                  "& .MuiInputBase-input": {
                    fontSize: 14,
                    fontWeight: "Medium",
                    fontFamily: "Inter",
                    textAlign: "left",
                    p: "0 !important",
                    marginLeft: "12px",
                  },
                }}
              >
                <InputLabel color="secondary" />
                {selectedIntegration && selectedIntegration.integration ? (
                  <Select
                    color="secondary"
                    disableUnderline
                    displayEmpty
                    value={selectedIntegration.integration}
                    disabled={loading}
                  >
                    {integration &&
                      integration.length >= 1 &&
                      integration.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.integration}
                          onClick={(event) => {
                            setSelectedIntegration(option);
                            setSelectedIntegrationStorage(option);
                            populateView(option.integration);
                          }}
                        >
                          {option.integration}{" "}
                          <mark className={styles.integrationIdentifier}>
                            {option.email}
                          </mark>{" "}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <div className="dot"></div>
                )}
                <FormHelperText />
              </FormControl>
              <img
                className={styles.openInFull}
                alt=""
                src="/open-in-full.svg"
              />
              <div className={styles.closeWrapper}></div>
            </header>
          ) : (
            <div
              style={{
                width: "100%",
                height: 60,
              }}
            ></div>
          )}

          <div
            style={{
              position: "absolute",
              top: 70,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRadius: 20,
                background: "#EFF1F6",
                padding: "5px 15px",
                zIndex: 3,
              }}
            >
              <EmailActionNavigationBreadCrumbs
                currentEmailAction={"Salesforce"}
                breadCrumbsHomeClick={() => {
                  navigate("/email/home");
                }}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {renderVariableContent()}
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default FormSalesforce;
