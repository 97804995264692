/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable space-before-function-paren */
import { useState, useEffect, useRef, useCallback } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import AddyLoading from "./AddyLoading";
import styles from "./LoanSubmissionTemplate.module.css";
import ChatModel from "../../models/chatModel";
import User from "../../models/user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import AddyWebSocket from "../../models/addyWebSocket";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import EmailActionNavigationBreadCrumbs from "../../components/EmailActionNavigationBreadCrumbs";

const FIELDS = [
  {
    key: "loanType",
    label: "Loan Type",
  },
  {
    key: "securityType",
    label: "Security Type",
  },
  {
    key: "propertyAddress",
    label: "Property Address",
  },
  {
    key: "estimatedMarketValue",
    label: "Estimated Market Value (EMV)",
  },
  {
    key: "loanAmountRequested",
    label: "Loan Amount Requested",
  },
  {
    key: "lvr",
    label: "LVR",
  },
  {
    key: "loanTerm",
    label: "Loan Term",
  },
  {
    key: "loanPurpose",
    label: "Loan Purpose",
  },
  {
    key: "interestRateEstimate",
    label: "Interest Rate Estimate",
  },
  {
    key: "servicingStructure",
    label: "Servicing Structure",
  },
  {
    key: "exitStrategy",
    label: "Exit Strategy",
  },
  {
    key: "borrowerType",
    label: "Borrower Type",
  },
  {
    key: "borrowerExperience",
    label: "Borrower Experience",
  },
  {
    key: "servicingCapacity",
    label: "Servicing Capacity (DSCR or ICR)",
  },
  {
    key: "financialStrength",
    label: "Financial Strength",
  },
  {
    key: "propertyType",
    label: "Property Type & Location Risk",
  },
  {
    key: "valuationSupport",
    label: "Valuation Support",
  },
  {
    key: "additionalSecurityProvided",
    label: "Additional Security Provided",
  },
  {
    key: "lvrPositioningAgainstPolicy",
    label: "LVR Positioning Against Policy",
  },
  {
    key: "loweredLvrOrAdditionalCollateral",
    label: "Lowered LVR or Additional Collateral",
  },
  {
    key: "prepaidInterestArrangement",
    label: "Prepaid Interest Arrangement",
  },
  {
    key: "guarantorSupportOrCoBorrower",
    label: "Guarantor Support or Co-Borrower",
  },
  {
    key: "confirmedExitStrategy",
    label: "Confirmed Exit Strategy (Refinance / Pre-Sales / Takeout Facility)",
  },
  {
    key: "meetsPolicy",
    label: "Meets Policy?",
  },
  {
    key: "requiresExceptionApproval",
    label: "Requires Exception Approval?",
  },
  {
    key: "escalationRequiredTo",
    label: "Escalation Required to",
  },
  {
    key: "supportingDocumentsRequired",
    label: "Supporting Documents Required",
  },
];

const LoanSubmissionTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tableDropDownRef = useRef(null);
  const tableDropdownListRef = useRef(null);
  // User data passed from chrome extension

  let data = {};
  let emailThread = "";
  let attachmentListBase64 = [];
  if (location?.state?.data) {
    data = location.state.data;
  }
  if (location?.state?.emailThread) {
    emailThread = location.state.emailThread;
  }
  if (location?.state?.attachmentListBase64) {
    attachmentListBase64 = location.state.attachmentListBase64;
  }
  const uid = data?.uid || null;
  const email = data?.email || null;
  const clientID = data?.clientID || null;
  const name = data?.name || "You";
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [startupErrorMesssage, setStartupErrorMessage] = useState("");

  const [editingFields, setEditingFields] = useState({});

  const windowListenForPostMessage = () => {
    window.addEventListener("message", (event) => {
      const origin = event.origin || event.originalEvent.origin;
      const data = event.data;

      if (data && data.key) {
        const action = data.key;
        const value = data.value;
        switch (action) {
          case "emailThread":
            emailThread = value;
            break;
          case "attachmentListBase64":
            attachmentListBase64 = value;
            break;
          default:
            break;
        }
      }
    });
  };

  async function callLLM(
    tableV,
    first,
    sampleRenderDataContacts,
    sampleRenderDataDeals = [],
    dealsPipeline = [],
    crmIntegrationF
  ) {
    console.log("CALLING LLM");
    try {
      if (first) setLoading(true);
      const response = await ChatModel.chat(
        email,
        name,
        emailThread,
        "loan-submission-template",
        attachmentListBase64,
        dealsPipeline
      );

      setEditingFields(
        Object.keys(response.chatResponse).reduce((acc, key) => {
          acc[key] = response.chatResponse[key] || "";
          return acc;
        }, {})
      );

      setErrorMessage("");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setStartupErrorMessage("Error extracting information from email");
    }
  }

  async function populateView() {
    try {
      await populateFromSalesforce();
    } catch (error) {
      console.error(error);
      setStartupErrorMessage("Error extracting fields from email");
    }
  }

  const onCopy = useCallback(() => {
    const htmlContent = `
      <table
  border="0"
  cellspacing="0"
  cellpadding="0"
  width="760"
  style="width: 570pt; border-collapse: collapse"
>
    <tbody>
        ${FIELDS.map(
          (f) =>
            `<tr style="height: 15pt">
<td
  width="233"
  style="
    width: 175pt;
    border: solid windowtext 1pt;
    border-top: none;
    background: #f2f2f2;
    padding: 0cm 5.4pt 0cm 5.4pt;
    height: 23.5pt;
  "
>
  <p class="MsoNormal">
    <b
      ><span
        style="font-size: 9pt; font-family: 'Assistant Light'; color: black"
        >${f.label}</span></b
    >


    <span style="font-size: 9pt; font-family: 'Assistant Light'; color: black"
      ><br /> </span
    ><i
      ><span
        style="font-size: 8pt; font-family: 'Assistant Light'; color: black"
        >${f.labelDescription || ""}</span
      ></i
    ><span style="font-size: 9pt; font-family: 'Assistant Light'; color: black"
      ><u></u><u></u
    ></span>
  </p>
</td>

            <td
              width="527"
              style="
            width: 395pt;
            border: solid windowtext 1pt;
            border-left: none;
            padding: 0cm 5.4pt 0cm 5.4pt;
            height: 15pt;
          "
            >
              <p class="MsoNormal">
                <span style="font-size: 9pt; font-family: 'Assistant Light'; color: black">
                  ${
                    typeof editingFields[f.key] === "string"
                      ? editingFields[f.key].replace(/(?:\r\n|\r|\n)/g, "<br>")
                      : ""
                  }<u></u>
                  <u></u>
                </span>
              </p>
            </td>
          </tr>
        `
        ).join("")}
    </tbody>
  </table>
    `;

    const blob = new Blob([htmlContent], { type: "text/html" });

    navigator.clipboard.write([
      new ClipboardItem({
        "text/html": blob,
      }),
    ]);
    toastify("Submission copied", true);
  }, [editingFields]);

  const clearHighlightedText = (id) => {
    // Send clear action to client webpage via WebSocket
    if (id) {
      AddyWebSocket.retrySendDataToWebsocket(
        "",
        `${id}-webpage`,
        "clear-all-highlighted-text",
        2
      );
    }
  };

  const highlightTextOnClient = (id, text) => {
    // Send highlight action to client webpage via WebSocket
    if (id) {
      AddyWebSocket.retrySendDataToWebsocket(
        text,
        `${id}-webpage`,
        "highlight-selected-field",
        2
      );
    }
  };

  useEffect(() => {
    windowListenForPostMessage();
    getUserData();

    // eslint-disable-next-line no-undef
    document.addEventListener("click", handleClickOutside);
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getUserData = async () => {
    const userInfo = await User.getUserInfo(email);
    populateView();
  };

  async function populateFromSalesforce() {
    for (let i = 0; i < 50; i++) {
      if (emailThread !== "") {
        break;
      }
      await new Promise((r) => setTimeout(r, 200));
    }
    if (emailThread === "") {
      return setStartupErrorMessage("Error getting email content");
    }

    await callLLM(null, true, null, null, [], "salesforce");
  }

  // Event handler for click events
  const handleClickOutside = (event) => {
    // If user clicks outside or inside of dropdown ref
    // Close the dropdown
    if (
      tableDropDownRef.current &&
      !tableDropDownRef.current.contains(event.target) &&
      tableDropdownListRef.current &&
      !tableDropdownListRef.current.contains(event.target)
    ) {
      if (
        !(
          tableDropdownListRef.current &&
          !tableDropdownListRef.current.contains(event.target)
        )
      ) {
      }
    }
  };

  const toastify = (message, success) => {
    if (success) {
      toast.success(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  function renderVariableContent() {
    if (startupErrorMesssage) {
      return (
        <div
          className="addy-custom-scroll"
          style={{
            width: "100%",
            paddingTop: "1rem",
            flex: 1,
            paddingTop: 70,
          }}
        >
          <div
            className={styles.secondaryText}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: ".25rem 1rem",
              gap: "1rem",
            }}
          >
            <b className={styles.h2Heading1}>Error</b>
            <div>{startupErrorMesssage}</div>
            <LoadingButton
              className={styles.button10}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#f7f8fc",
                fontSize: "16",
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                borderRadius: "8px",
                "&:hover": {
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                },
                "&:disabled": {
                  background: "lightgrey",
                  color: "white",
                },
                height: 32,
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Try again
            </LoadingButton>
          </div>
        </div>
      );
    } else if (errorMessage) {
      return (
        <div
          className="addy-custom-scroll"
          style={{
            width: "100%",
            paddingTop: "1rem",
            flex: 1,
            paddingTop: 70,
          }}
        >
          <div
            className={styles.secondaryText}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: ".25rem 1rem",
              gap: "1rem",
            }}
          >
            <b className={styles.h2Heading1}>Error</b>
            <div>{errorMessage}</div>
            <LoadingButton
              className={styles.button10}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#f7f8fc",
                fontSize: "16",
                background:
                  "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                borderRadius: "8px",
                "&:hover": {
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                },
                "&:disabled": {
                  background: "lightgrey",
                  color: "white",
                },
                height: 32,
              }}
              onClick={() => {
                setErrorMessage("");
              }}
            >
              Try again
            </LoadingButton>
          </div>
        </div>
      );
    } else if (loading) {
      return <AddyLoading />;
    } else {
      return (
        <>
          <div
            className="addy-custom-scroll"
            style={{
              width: "100%",
              paddingTop: 70,
              flex: 1,
            }}
          >
            <section className={styles.expandableInstanceWrapper}>
              <div className={styles.expandableInstance}>
                {/* <OpenExternalSiteButton /> */}
                <div className={styles.expandableInstanceInner}>
                  <div className={styles.tableWrapper}>
                    <table
                      className={styles.templateTable}
                      id="loan-submission-template-table"
                    >
                      <tbody>
                        {FIELDS.map((f) => (
                          <tr key={f.key}>
                            <th>
                              {f.label}
                              {!!f.labelDescription && (
                                <>
                                  <br />
                                  <small>{f.labelDescription}</small>
                                </>
                              )}
                            </th>
                            <td>
                              <TextareaAutosize
                                value={editingFields[f.key]}
                                onChange={(e) => {
                                  setEditingFields((old) => ({
                                    ...old,
                                    [f.key]: e.target.value || "",
                                  }));
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className={styles.bottomSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                padding: "0 1rem",
                boxSizing: "border-box",
                gap: "1rem",
              }}
            >
              <LoadingButton
                className={styles.button10}
                startIcon={<CloseOutlined />}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#ab7ff1",
                  fontSize: "16",
                  background: "white",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#ede2ff",
                  },
                  "&:disabled": {
                    background: "lightgrey",
                    color: "white",
                  },
                  height: 32,
                }}
                onClick={() => {
                  navigate("/email/home");
                }}
              >
                Close
              </LoadingButton>

              <LoadingButton
                className={styles.button11}
                startIcon={
                  <img width="18px" height="18px" src="/copy-light.svg" />
                }
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#f7f8fc",
                  fontSize: "16",
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                  borderRadius: "8px",
                  "&:hover": {
                    background:
                      "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                  },
                  "&:disabled": {
                    background: "lightgrey",
                    color: "white",
                  },
                  height: 32,
                }}
                onClick={() => {
                  onCopy();
                }}
              >
                Copy submission
              </LoadingButton>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <div className={styles.commercial}>
      <main className={styles.topBarNewMessageParent}>
        <div
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <header className={styles.topBarNewMessage}>
            <FormControl
              className={styles.button}
              variant="standard"
              sx={{
                borderColor: "#eff1f6",
                borderStyle: "SOLID",
                borderTopWidth: "1px",
                borderRightWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                width: "59.34065934065934%",
                height: "32px",
                m: 0,
                p: 0,
                "& .MuiInputBase-root": {
                  m: 0,
                  p: 0,
                  minHeight: "32px",
                  justifyContent: "center",
                  display: "inline-flex",
                },
                "& .MuiInputLabel-root": {
                  m: 0,
                  p: 0,
                  minHeight: "32px",
                  display: "inline-flex",
                },
                "& .MuiMenuItem-root": {
                  m: 0,
                  p: 0,
                  height: "32px",
                  display: "inline-flex",
                },
                "& .MuiSelect-select": {
                  m: 0,
                  p: 0,
                  height: "32px",
                  alignItems: "center",
                  display: "inline-flex",
                },
                "& .MuiInput-input": { m: 0, p: 0 },
                "& .MuiInputBase-input": {
                  fontSize: 14,
                  fontWeight: "Medium",
                  fontFamily: "Inter",
                  textAlign: "left",
                  p: "0 !important",
                  marginLeft: "12px",
                  paddingRight: "12px",
                },
              }}
            >
              <InputLabel color="secondary" />
              <Select
                color="secondary"
                disableUnderline
                displayEmpty
                disabled
                value={"unique"}
              >
                <MenuItem
                  key={"unique"}
                  value={"unique"}
                  onClick={(event) => {}}
                >
                  Loan Submission Template{" "}
                  <mark className={styles.integrationIdentifier}>
                    {email || ""}
                  </mark>
                </MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>

            <img className={styles.openInFull} alt="" src="/open-in-full.svg" />
            <div className={styles.closeWrapper}></div>
          </header>

          <div
            style={{
              position: "absolute",
              top: 70,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRadius: 20,
                background: "#EFF1F6",
                padding: "5px 15px",
                zIndex: 3,
              }}
            >
              <EmailActionNavigationBreadCrumbs
                currentEmailAction={"Loan Submission Template"}
                breadCrumbsHomeClick={() => {
                  navigate("/email/home");
                }}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {renderVariableContent()}
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default LoanSubmissionTemplate;
