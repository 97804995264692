import "./ComparisonModal.css";
import "./MessageText.css";
import React, { useState, useRef } from 'react';
import { Dialog, IconButton, DialogActions, Button, Divider, Typography } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { marked } from "marked";
import { sanitize } from "dompurify";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseIcon from '@mui/icons-material/Close';
import AddyWebSocket from "../models/addyWebSocket";

const ComparisonModal = ({ open, onClose, documentsCompared, message, clientID }) => {
    const [likeSrc, setLikeSrc] = useState("/thumb.svg");
    const [dislikeSrc, setDislikeSrc] = useState("/thumb-1.svg");
    const copyIconRef = useRef(null);
    const feedbackContainerRef = useRef(null);

    const [showAll, setShowAll] = useState(true);

    const getMarkedDownText = (markdownText) => {
      const rawMarkup = marked(markdownText, { sanitize: true });

      const lineBreaksAdded = rawMarkup.replace(
        /\\n/g,
        "<br class='addy-line-break' />"
      );
  
      const purifiedMarkup = sanitize(lineBreaksAdded);
      return { __html: purifiedMarkup };
    };

    const handleInsertClick = () => {
      console.log("Insert clicked");
      // Send insert action to client via WebSocket
      console.log("Insert clicked 2");
      const copiedText = message?.message?.replace(/\\n/g, "\n");
      AddyWebSocket.sendDataToWebsocket(
        copiedText,
        `${clientID}-webpage`,
        "insertTextInReplyEditor"
      );
    };

    const handleLikeClick = () => {
          setLikeSrc("/thumbs-up-filled.png");
          setDislikeSrc("/thumb-1.svg");
      };

      const handleDislikeClick = () => {
        setDislikeSrc("/thumbs-down-filled.png");
        setLikeSrc("/thumb.svg");
      };

    const handleCopyClick = () => {
      // copyIconRef.current.src = "/check_stroke.svg";
      // setTimeout(() => {
      //   copyIconRef.current.src = "/copy_stroke.svg";
      // }, 5000);

      const copiedText = message?.message?.replace(/\\n/g, "\n");
      AddyWebSocket.sendDataToWebsocket(
        copiedText,
        `${clientID}-webpage`,
        "copy",
        3
      );

      navigator.clipboard.writeText(copiedText).then(
        function () {
          console.log("Text copied to clipboard");
        },
        function (err) {
          console.error("Error copying text to clipboard: ", err);
        }
      );
    };

    const onRephraseButtonClick = () => {}

    const ComparisonCard = ({ title, text, sources, reasoning, path }) => {
      return (
        <div className="comparison-card">
          {path && path.split(" > ").length > 1 ? (
            <CustomTooltip title={path}>
              <div className="comparison-title">
                {(() => {
                  const pathSegments = path
                    .split(" > ")
                    .map((segment) => segment.trim());
                  const targetIndex = pathSegments[1]
                    ?.toLowerCase()
                    .includes("mortgage insurers")
                    ? 2
                    : 1;
                  return pathSegments[targetIndex]
                    ? pathSegments[targetIndex].charAt(0).toUpperCase() +
                        pathSegments[targetIndex].slice(1)
                    : title;
                })()}
              </div>
            </CustomTooltip>
          ) : (
            <div className="comparison-title">{title}</div>
          )}
          <div className="comparison-content">{text}</div>

          {sources && sources.length > 0 && (
            <div>
              <div className="comparison-index">Sources</div>
              <div className="source-buttons">
                {sources.map((source, index) => (
                  <CustomTooltip
                    title={comparisonTooltipComponent(source, title)}
                  >
                    <div className="source-button">{index + 1}</div>
                  </CustomTooltip>
                ))}
              </div>
            </div>
          )}

          {/* {reasoning && (
            <div className="comparison-section">
              <div className="comparison-index">Reasoning</div>
              <div className="source-buttons">
                <CustomTooltip
                  title={comparisonTooltipComponent(reasoning, title)}
                >
                  <div className="source-button">1</div>
                </CustomTooltip>
              </div>
            </div>
          )} */}
        </div>
      );
    };

    const comparisonTooltipComponent = (source, index) => {
      return (
        <div className="tooltip-content">
          <div className="tooltip-title">
            Document Title {index}
          </div>
          <div className="tooltip-text">
            {source.text}
          </div>
          <div className="tooltip-footer">
            <a href={source.url} target="_blank" rel="noopener noreferrer">
              View source
            </a>
          </div>
        </div>
      );
    };

    const CustomTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#ffffff",
        color: "#333",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        fontSize: "14px",
        borderRadius: "8px",
        padding: "8px 16px",
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: "#ffffff", 
      },
    }));  

    return (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          sx={{
            borderRadius: "16px",
            backdropFilter: "blur(14px)",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            "& .MuiDialog-paper": {
                color: "#758199",
                width: "650px",
                height: "800px",
                borderRadius: "16px",
            },
            fontFamily: "Inter"
        }}
        >  
          <dev className="comparison-modal-title">
            {message.userMessage}
          </dev>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              width: 6,
              height: 6, 
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          {/* <div className="text-field5">
            <div className="h-heading-group">
              <div className="scroll-bar5">
                <div className="image-placeholder1">
                  <div className="wrapper-union">
                    <img
                      className="union-icon"
                      loading="lazy"
                      alt={`${message.type}`}
                      src={`${message.imageURL}`}
                    />
                  </div>
                </div> */}
                  <div className="frame-parent1" style={{padding: "40px 30px"}}>
                    {/* <div className="h2-heading-parent24">
                      <b className="h2-heading49">
                        {message.name && message.name.length > 40
                          ? `${message.name.substring(0, 39)}...`
                          : message.name}
                      </b>
                      {message.type === "user" && message.selectedText && (
                        <div className="selected-text-parent">
                          <div className="selected-text-header-parent">
                            <div className="selected-text-header">
                              {message.selectedTextTitle || "Selected text"}
                            </div>
                          </div>
                          <div className="selected-text">
                            {message.selectedText.length > 250
                              ? message.selectedText.substring(0, 250) + "..."
                              : message.selectedText}
                          </div>
                        </div>
                      )}
                      <div className="h2-heading50">
                          {/* {message.action == "thinking" && (
                            <div className="dot"></div>
                          )} */}

                          {/* eslint-disable-next-line max-len */}
                          {/* <div className={`model-response-text ${message.action == "thinking" ? "hide" : ""}`}>
                                                  {parse(message.message)}
                                              </div> */}
                          {/* <div
                            className={`model-response-text`}
                            dangerouslySetInnerHTML={getMarkedDownText(
                              message.message
                            )}
                          ></div>
                      </div>
                    </div>  */}
                    {documentsCompared && documentsCompared.length > 0 && (
                      <>
                        <div className="comparison-list">
                          <div className="comparison-list-front">
                            <img
                              className="addy-check-security tooltip-1"
                              width="16"
                              height="16"
                              src="/book.png"
                            />
                            <div className="comparison-title">Investor Guidelines Comparison</div>
                          </div>
                          <div className="comparison-list-end" onClick={() => setShowAll(!showAll)}>
                            {!showAll && (
                              <div className="comparison-number">
                                {documentsCompared.length - 3 > 0 && (documentsCompared.length - 3 < 5 ? `+${documentsCompared.length - 3}` : `5+`)}
                              </div>
                            )}
                            <div className="Comparison-view-all" onClick={() => setShowAll(!showAll)}>{showAll ? 'View less' : 'View all'}</div>
                          </div>
                        </div>

                        <div className="Comparison-list addy-custom-scroll">
                          {documentsCompared.map((context, index) => (
                            ((!showAll && index < 3) || showAll) && (
                              <ComparisonCard
                                key={context.id || `${context.title}-${index}`}
                                title={context.title}
                                text={context.text}
                                sources={context.source}
                                reasoning={context.reasoning}
                                path={context.filePath}
                              />
                            )
                          ))}
                        </div>
                      </>
                    )}
                  
                      <div
                        className={`feedback-container`}
                      >
                        {/* <div className="addy-copy-response-div">
                          <div
                            className="copy-response thumb-group"
                            onClick={handleInsertClick}
                          >
                            <img
                              className="thumb-icon2"
                              alt="insert"
                              src="/insert_stroke.svg"
                              data-toggle="tooltip"
                              title="Insert in email editor"
                            />
                            <div className="h2-heading52">Insert</div>
                          </div>

                          <div
                            className="copy-response thumb-group"
                            onClick={handleCopyClick}
                          >
                            <img
                              className="thumb-icon2"
                              alt="copy"
                              src="/copy_stroke.svg"
                              data-toggle="tooltip"
                              title="Copy to clipboard"
                              ref={copyIconRef}
                            />
                            <div className="h2-heading52">Copy</div>
                          </div>
                          <div
                            className="copy-response thumb-group"
                            onClick={onRephraseButtonClick}
                          >
                            <img
                              className="thumb-icon2"
                              alt="rephrase"
                              src="/rephrase.svg"
                              data-toggle="tooltip"
                              title="Rephrase the provided email"
                            />
                            <div className="h2-heading52">Rephrase</div>
                          </div>
                        </div> */}

                        <div className="addy-rate-response-div">
                          <div className="thumb-parent">
                              <img
                                  data-toggle="tooltip"
                                  title="Good response"
                                  className="like-thumb thumb-icon"
                                  loading="lazy"
                                  alt=""
                                  src={likeSrc}
                                  onClick={handleLikeClick}
                              />
                              <div className="h2-heading51"></div>
                          </div>
                          <div className="thumb-parent">
                            <img
                              data-toggle="tooltip"
                              title="Bad response"
                              className="dislike-thumb thumb-icon"
                              alt=""
                              src={dislikeSrc}
                              onClick={handleDislikeClick}
                            />
                            <div className="h2-heading52"></div>
                          </div>
                          <div
                            className="copy-response thumb-group"
                            onClick={handleCopyClick}
                          >
                            {/* <img
                              className="thumb-icon2"
                              alt="copy"
                              src="/copy_stroke.svg"
                              data-toggle="tooltip"
                              title="Copy to clipboard"
                              ref={copyIconRef}
                            /> */}
                            <div className="h2-copy">Copy</div>
                          </div>
                        </div>
                      </div>
                    </div>
       
        </Dialog>
      );
    };

export default ComparisonModal;

// const ComparisonCard = ({ title, text }) => (
//   <Box
//     sx={{
//       mb: 2,
//       p: 2,
//       border: '1px solid #e0e0e0',
//       borderRadius: '8px',
//       backgroundColor: '#f9f9f9',
//     }}
//   >
//     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
//       {title}
//     </Typography>
//     <Typography variant="body2">{text}</Typography>
//   </Box>
// );
