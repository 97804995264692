import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Chat from "./pages/Chat";
import Form from "./pages/Form";
import ConnectHubspot from "./pages/ConnectHubspot";
import ConnectSalesforce from "./pages/ConnectSalesforce";
import EmailHome from "./pages/EmailHome";
import GlobalVariables from "./models/globalVariables";
import BackgroundSearch from "./pages/BackgroundSearch";
import FormSalesforce from "./pages/FormSalesforce/FormSalesfroce";
import LoanSubmissionTemplate from "./pages/LoanSubmissionTemplate/LoanSubmissionTemplate";
// import ChatBigAssistantDropdown from "./pages/ChatBigAssistantDropdown";
// import ChatAssistantDropdown from "./pages/ChatAssistantDropdown";
// import ChatAssistantDropdown1 from "./pages/ChatAssistantDropdown1";
// import ChatAssistantDropdown2 from "./pages/ChatAssistantDropdown2";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  console.log("env " + process.env);

  useEffect(() => {
    for (let i = 0; i < GlobalVariables.getClientDomain().length; i++) {
      if (GlobalVariables.getClientDomain()[i].includes(document.referrer)) {
        localStorage.setItem("domainPath", document.referrer);
      }
    }

    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/chat-big-assistant-dropdown":
        title = "";
        metaDescription = "";
        break;
      case "/chat-assistant-dropdown":
        title = "";
        metaDescription = "";
        break;
      case "/chat-assistant-dropdown1":
        title = "";
        metaDescription = "";
        break;
      case "/chat-assistant-dropdown2":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      // eslint-disable-next-line no-undef
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Chat />} />
      <Route path="/form" element={<Form />} />
      <Route path="/form-salesforce" element={<FormSalesforce />} />
      <Route
        path="/loan-submission-template"
        element={<LoanSubmissionTemplate />}
      />
      <Route path="/connectHubspot" element={<ConnectHubspot />} />
      <Route path="/connectSalesforce" element={<ConnectSalesforce />} />
      <Route path="/email/home" element={<EmailHome />} />
      <Route
        path="/email/home/background-search"
        element={<BackgroundSearch />}
      />

      {/* <Route
                path="/chat-big-assistant-dropdown"
                element={<ChatBigAssistantDropdown />}
            />
            <Route
                path="/chat-assistant-dropdown"
                element={<ChatAssistantDropdown />}
            />
            <Route
                path="/chat-assistant-dropdown1"
                element={<ChatAssistantDropdown1 />}
            />
            <Route
                path="/chat-assistant-dropdown2"
                element={<ChatAssistantDropdown2 />}
            /> */}
    </Routes>
  );
}
export default App;
