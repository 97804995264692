import "./ButtonInstance.css";
import { useState, useEffect } from "react";
import {
  documentPrompts,
  sampleSelectedTextActions,
  defaultAssistantsPrompts,
} from "../helpers/prompts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules"; // Import Navigation module
import "swiper/swiper-bundle.css";
import SuggestedPrompts from "./SuggestedPrompts.js";
import { Tooltip } from "@mui/material";

const ButtonInstance = (props) => {
  const [textareaValue, setTextareaValue] = useState("");
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const [selectedType, setSelectedType] = useState(
    "What do you want to do with this text?"
  );
  const [linesCount, setLinesCount] = useState(0);
  const [viewTitle, setViewTitle] = useState("Selected text");
  const [maxCharsPerLine, setMaxCharsPerLine] = useState(30);
  const [selectedTextActions, setSelectedTextActions] = useState(
    sampleSelectedTextActions
  );
  const [compareMode, setCompareMode] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const clientHostname = urlParams.get("clientHostname");

  const clientID = props.clientID;
  const data = props.data;
  const currentlyTyping = props.currentlyTyping;
  const numberOfMessagesInChat = props.numberOfMessagesInChat;
  const currentView = props.currentView;
  const uid = props.uid;
  const isDocumentChat = props.isDocumentChat;

  let titleMessage;
  let descriptionMessage;
  if (clientHostname && !clientHostname.includes("addy")) {
    // If the client hostname exists (meaning this is embeded in another) website
    // for instance chrome extension and hostname is not addy
    titleMessage = "How can I help";
    descriptionMessage = "";
    if (
      clientHostname === "app.heynouri.ai" ||
      clientHostname === "nourimortgagedev.addy.so"
    ) {
      titleMessage = "Search lending guidelines";
      descriptionMessage =
        "I can answer questions about mortgage lending guidelines, loan programs, and more";
    }
  } else {
    titleMessage =
      props.selectedAssistant?.appID ===
      "addy-default-assistant-c66e0609-371e-49b2-bb4a-5186526b2a9d"
        ? "Search lending guidelines"
        : "How can I help";
    descriptionMessage =
      props.selectedAssistant?.appID ===
      "addy-default-assistant-c66e0609-371e-49b2-bb4a-5186526b2a9d"
        ? "I can answer questions about mortgage lending guidelines, loan programs, and more"
        : `I can answer questions from the ${
            props.selectedAssistant?.appName || ""
          } assistant training data`;
  }

  const [selectedText, setSelectedText] = useState(
    data?.action === "selectedText" && data?.text ? data.text : ""
  );

  // eslint-disable-next-line no-undef
  const chatInfo = document.querySelector("#addy-chat-info");
  if (selectedText && selectedText.length && chatInfo) {
    chatInfo.remove();
  }

  // When the component loads for the first time, once, make the cursor
  // focus on the textarea
  useEffect(() => {
    const textarea = document.querySelector(".addy-prompt-input");
    if (textarea) {
      textarea.focus();
    }
  }, []);

  useEffect(() => {
    if (props.selectedAssistant) {
      const randomPrompts2 = defaultAssistantsPrompts[
        props?.selectedAssistant?.defaultId || "addy-assistant-001"
      ].sort(() => 0.5 - Math.random());
      setSuggestedPrompts(randomPrompts2.slice(0, 3));
    }
  }, [props.selectedAssistant]);

  const setButtonInstancePosition = (numMessages, currentView) => {
    const alignInputBottom = props?.alignInputBottom;
    const buttonInstance = document.querySelector(".button-instance");
    if (!buttonInstance) return;
    if (
      numMessages === 0 &&
      currentView === "select-assistant" &&
      !selectedText
    ) {
      // Set the postion to the center of the page
      buttonInstance.style.bottom = alignInputBottom ? "20px" : "40%";
    } else {
      buttonInstance.style.bottom = "20px";
    }
  };

  const toggleCompareMode = async (compareModeT) => {
    const newCompareMode = !compareModeT;
    await props.onCompareModeChange(newCompareMode);
    console.log("newCompareMode");
    console.log(newCompareMode);
    setCompareMode(newCompareMode);
  };

  // When number of messages in chat changes, set button instance margin bottom
  useEffect(() => {
    setButtonInstancePosition(numberOfMessagesInChat, currentView);
  }, [numberOfMessagesInChat, currentView, selectedText]);

  useEffect(() => {
    if (clientID && uid) {
      // Whenever a user selects text on the page, listen for it
      listenForSelectedFromClientWebpage(clientID);
    }
    // Set suggested prompts to be 10 random prompts from samplePrompts
    const randomPrompts = ["Write an email"];
    const randomPrompts2 = defaultAssistantsPrompts[
      props?.selectedAssistant?.defaultId || "addy-assistant-001"
    ].sort(() => 0.5 - Math.random());
    setSuggestedPrompts(randomPrompts.concat(randomPrompts2.slice(0, 3)));

    if (data && data["action"] === "selectedText") {
      setSelectedText(data["text"]);
    }
  }, [clientID]); // Run this effect when clientID changes

  useEffect(() => {
    // remove chat info onboarding text if selected text is present
    if (selectedText && selectedText.length && chatInfo) {
      chatInfo.remove();
    }
  }, [selectedText]); // Reload component on selected text change

  // Reload component on currentlyTyping change
  useEffect(() => {}, [currentlyTyping]);

  // Reload when props change
  useEffect(() => {
    if (
      props.currentEmailAction === "Analyze attachment" &&
      props.selectedAttachment
    ) {
      setSelectedText(props.selectedAttachment.name || "");
      setSelectedType("More ideas for you");
      setViewTitle("Chat with your document");
      if (
        props.selectedAttachment.tag &&
        documentPrompts[props.selectedAttachment.tag]?.length
      ) {
        setSelectedTextActions(documentPrompts[props.selectedAttachment.tag]);
      } else {
        setSelectedTextActions([
          "Extract text",
          "Translate",
          "Write a blog post about this document",
        ]);
      }
      // get the analyze attachment parent indicator
      // const analyzeAttachmentParent = document.querySelector(".selected-text-parent");
      // if (analyzeAttachmentParent) {
      //     analyzeAttachmentParent.classList.add("analyze-attachment-parent");
      // }
    } else if (props.currentEmailAction === "Analyze document") {
      setSelectedText(props.selectedDocument?.name || "My document.pdf");
      setSelectedType("More ideas for you");
      setViewTitle("Chat with your document");
      if (
        props.selectedDocument?.tag &&
        documentPrompts[props.selectedDocument?.tag]?.length
      ) {
        setSelectedTextActions(documentPrompts[props.selectedDocument.tag]);
      } else {
        setSelectedTextActions([
          "Extract text",
          "Translate",
          "Write a blog post about this document",
        ]);
      }
    } else if (
      props.currentEmailAction === "Other files" &&
      props.selectedAttachment
    ) {
      setSelectedText(props.selectedAttachment.name || "");
      setSelectedType("More ideas for you");
      setViewTitle("Chat with your file");
    } else {
      if (selectedText) {
        setSelectedType("What do you want to do with this text?");
        setViewTitle("Selected text");
        setSelectedTextActions(sampleSelectedTextActions);
      }
    }
    if (props.maxCharsPerLine) {
      setMaxCharsPerLine(props.maxCharsPerLine);
    }
  }, [props]);

  // Reload when linesCount changes
  useEffect(() => {}, [linesCount]);

  // Handle change event of textarea
  const handleChange = (event) => {
    setTextareaValue(event.target.value);
    const textareaWidth = event.target.getBoundingClientRect().width;
    const lineBreakCount = event.target.value.split(/\r\n|\r|\n/).length;
    const lengthBasedCount = calculateLineCount(textareaWidth);
    const count =
      event.target.value === ""
        ? 1
        : Math.max(lineBreakCount, lengthBasedCount);
    setLinesCount(count <= 5 ? count : 5);
  };

  const calculateLineCount = (elementWidth) => {
    const charWidth = 7;
    const maxCharactersPerLine = Math.floor(elementWidth / charWidth);
    return Math.ceil(textareaValue.length / maxCharactersPerLine);
  };

  // Handle key down event of textarea
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && (event.shiftKey || event.altKey)) {
      // If Shift+Enter or Alt+Enter is pressed, create a new line
      event.stopPropagation();
      if (linesCount < 5) {
        setTextareaValue((prevValue) => prevValue + "\n");
      }
    } else if (event.key === "Enter") {
      onClick(textareaValue, selectedText, compareMode);
      event.preventDefault();
    }
  };

  const onClick = () => {
    if (!textareaValue || currentlyTyping) return;
    console.log("clicked here");
    console.log(compareMode);
    props.onClick(
      textareaValue,
      selectedText,
      compareMode,
      compareMode ? "true" : "false"
    );
    setTextareaValue("");
    setLinesCount(1);
    // Reset the selected text
    setSelectedText("");
  };

  const onSuggestedPromptClick = (prompt) => {
    if (currentlyTyping) return;
    // Reset the selected text
    props.onClick(
      prompt,
      selectedText,
      compareMode,
      compareMode ? "true" : "false"
    );
    setSelectedText("");
  };
  const onSelectedTextClose = async () => {
    if (isDocumentChat) {
      await props.onCloseAttachment();
    }
    setSelectedText("");
  };

  const closeHotTip = () => {
    const hotTipDiv = document.getElementById("hot-tip-div");
  };

  const suggestedPromptsCarousel = (prompts) => {
    return (
      <SuggestedPrompts
        prompts={prompts}
        onClick={(prompt) => onSuggestedPromptClick(prompt)}
      />
    );
  };

  const listenForSelectedFromClientWebpage = async (id) => {
    // eslint-disable-next-line no-undef
    window.addEventListener("message", (event) => {
      const data = event.data;
      // console.log("Data received from window post message", data);
      if (data && data.key) {
        const action = data.key;
        const text = data.value;
        switch (action) {
          case "selectedText":
            setSelectedText(text);
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <div
      className={`button-instance ${
        props.isHomePage ? "" : "button-instance-padding"
      }`}
    >
      {props.hideHotTipContainer &&
        !props.hideSuggestedPrompts &&
        !selectedText && (
          <div className="lightning-instance-parent">
            {
              <div
                className="more-suggestions-title gradient-title"
                style={{
                  textAlign: "center",
                  paddingLeft: "5px",
                  fontSize: "26px",
                  // letterSpacing: "0px",
                  fontWeight: "bold",
                  color: "#485165",
                  // paddingBottom: "20px",
                  color: "rgba(0, 0, 0, 0.9)",
                  color: "#a179e4",
                  // color: "#745dde",
                }}
              >
                {titleMessage}
              </div>
            }

            {
              <p
                className="add-necessary-bits small-description-text description-helper-text"
                style={{
                  color: "#485165",
                  width: "100%",
                  // paddingBottom: "15px",
                }}
              >
                {descriptionMessage}
              </p>
            }
          </div>
        )}

      <div className="button-instance-container">
        {selectedText?.trim() ? (
          <div className="selected-text-container">
            <div className="selected-text-parent">
              <div className="selected-text-header-parent">
                <div className="selected-text-header">{viewTitle}</div>

                <div
                  className="selected-text-close-button"
                  onClick={() => onSelectedTextClose()}
                >
                  <img width="12" height="12" src="/close.svg" />
                </div>
              </div>
              <div className="selected-text">
                {selectedText.length > 250
                  ? selectedText.substring(0, 250) + "..."
                  : selectedText}
              </div>
            </div>

            <div className="actions-container">
              <div className="action-title">{selectedType}</div>

              <div className="selected-text-action-div">
                {selectedTextActions.map((action, index) => (
                  <div
                    onClick={() => onSuggestedPromptClick(action)}
                    key={index}
                    className="suggestion-button selected-text-action"
                  >
                    {action}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : numberOfMessagesInChat === 0 ? (
          !props.hideHotTipContainer && (
            <div className="hot-tip-parent-container">
              <div
                className="selected-text-parent hot-tip-parent"
                id="hot-tip-div"
              >
                <div className="selected-text-header-parent">
                  <div className="selected-text-header">Hot tip!</div>

                  {/* <div className="selected-text-close-button" onClick={() => closeHotTip()}>
                                    <img width="12" height="12" src="/close.svg" />
                                </div> */}
                </div>
                <div className="selected-text">
                  Press and hold the{" "}
                  <mark className="focus-text-mark">`Control` </mark>
                  key, then highlight text on any website to quickly open Addy
                  AI. Try it now!
                </div>
              </div>

              {!props.hideSuggestedPrompts && (
                <div className="lightning-instance-parent">
                  {props.showMoreSuggestionsForYouTitle && (
                    <div className="more-suggestions-title">
                      More suggestions for you
                    </div>
                  )}
                  <div className="lightning-instance tooltip-5">
                    {suggestedPrompts.map((prompt, index) => (
                      <div
                        onClick={() => onSuggestedPromptClick(prompt)}
                        key={index}
                        className="suggestion-button"
                      >
                        {prompt}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )
        ) : (
          !props.hideSuggestedPrompts && (
            <div className="lightning-instance-parent">
              {props.showMoreSuggestionsForYouTitle && (
                <div className="more-suggestions-title">
                  More suggestions for you
                </div>
              )}
              <div className="lightning-instance tooltip-5">
                {suggestedPrompts.map((prompt, index) => (
                  <div
                    onClick={() => onSuggestedPromptClick(prompt)}
                    key={index}
                    className="suggestion-button"
                  >
                    {prompt}
                  </div>
                ))}
              </div>
            </div>
          )
        )}

        <div className="button-instance-inner tooltip-6">
          <div
            className="lightning-parent"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <img
                            className="lightning-icon"
                            loading="lazy"
                            alt=""
                            src="/lightning.svg"
                        /> */}
            <textarea
              className="addy-prompt-input"
              style={{ height: `${linesCount}em` }}
              placeholder={props.placeholder || "Ask me anything..."}
              value={textareaValue}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />

            <div className="input-actions-div">
              <div>
                {/* Chip for comparison */}
                <Tooltip
                  key={"compare-mode-button"}
                  title={"Compare investor guidelines"}
                  arrow
                >
                  <div
                    onClick={() => toggleCompareMode(compareMode)}
                    className="input-action-button"
                    style={
                      compareMode
                        ? {
                            color: "#8740FF",
                            border: "1px solid #D5C9EE",
                            backgroundColor: "#DBD3EE",
                          }
                        : {
                            color: "#6E717C",
                            border: "1px solid #D5D6DB",
                            backgroundColor: "transparent",
                          }
                    }
                  >
                    <img
                      src={
                        compareMode
                          ? "/compare-logo-purple.svg"
                          : "/compare-logo-grey.svg"
                      }
                      width="18"
                      height="18"
                    />
                    Compare
                  </div>
                </Tooltip>
              </div>

              <div>
                <img
                  data-toggle="tooltip"
                  title="Send message"
                  className="button-icon"
                  loading="lazy"
                  alt="Go"
                  onClick={() =>
                    onClick(textareaValue, selectedText, compareMode)
                  }
                  // Change the source based on current value of textarea
                  src={
                    textareaValue && !currentlyTyping
                      ? "/enabled-button@2x.png"
                      : "/button@2x.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {numberOfMessagesInChat === 0 && !props.hideSuggestedPrompts && (
          <div
            className="lightning-instance tooltip-5"
            style={{ justifyContent: "center" }}
          >
            {/* {suggestedPrompts.map((prompt, index) => (
                        <div onClick={() => onSuggestedPromptClick(prompt)} key={index} className="suggestion-button">
                            {prompt}
                        </div>
                    ))} */}
            {suggestedPromptsCarousel(suggestedPrompts)}
          </div>
        )}

        {!props.hideFeedbackRequestText && (
          <div className="selected-text feedback-request-text">
            <a href="https://forms.gle/z5mJsnJKNGiX7SGt8" target="_blank">
              Give us feedback
            </a>{" "}
            to help improve Addy AI
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonInstance;
