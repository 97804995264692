const whiteLabelConfig = {
    "nourimortgagedev.addy.so": {
        theme: "nouri",
        title: "Nouri AI | Nouri AI Assistant for Brokers",
        favicon: "/assets/ico/nouriMortgage-logo.ico",
        faviconSize: "32x32",
        logo: "/assets/login/norui-logo.svg",
        leftImage: "/assets/login/loginLeftImage.svg",
        appName: "Nouri AI",
        appPageTitle: "Nouri",
        onMicrosoft: false,
        onExtension: false,
        helpUrl: "https://heynouri.ai",
    },
    "nourimortgagedev.heynouri.ai": {
        theme: "nouri",
        title: "Nouri AI | Nouri AI Assistant for Brokers",
        favicon: "/assets/ico/nouriMortgage-logo.ico",
        faviconSize: "32x32",
        logo: "/assets/login/norui-logo.svg",
        leftImage: "/assets/login/loginLeftImage.svg",
        appName: "Nouri AI",
        appPageTitle: "Nouri",
        onMicrosoft: false,
        onExtension: false,
        helpUrl: "https://heynouri.ai",
    },
    "app.heynouri.ai": {
        theme: "nouri",
        title: "Nouri AI | Nouri AI Assistant for Brokers",
        favicon: "/assets/ico/nouriMortgage-logo.ico",
        faviconSize: "32x32",
        logo: "/assets/login/norui-logo.svg",
        leftImage: "/assets/login/loginLeftImage.svg",
        appName: "Nouri AI",
        appPageTitle: "Nouri",
        onMicrosoft: false,
        onExtension: false,
        helpUrl: "https://heynouri.ai",
    },
    "assetline.addy.so": {
        theme: "assetline",
        title: "Assetline AI | Assetline AI Assistant for Brokers",
        favicon: "/assets/ico/assetline-logo.ico",
        faviconSize: "32x32",
        logo: "/assets/login/assetline-logo.svg",
        leftImage: "/assets/login/loginLeftImage.svg",
        appName: "Assetline AI",
        appPageTitle: "Assetline",
        onMicrosoft: true,
        onExtension: false,
        helpUrl: "https://assetline.com.au/contact",
    },
    "diya.addy.so": {
        theme: "diya",
        title: "Diya AI | Diya AI Assistant for Brokers",
        favicon: "/assets/ico/diya-favicon.ico",
        faviconSize: "32x32",
        logo: "/assets/login/diya-logo.svg",
        leftImage: "/assets/login/loginLeftImage.svg",
        appName: "Diya AI",
        appPageTitle: "Diya",
        onMicrosoft: true,
        onExtension: false,
        helpUrl: "https://diyafinance.com",
    },
    "diyadev.addy.so": {
        theme: "diya",
        title: "Diya AI | Diya AI Assistant for Brokers",
        favicon: "/assets/ico/diya-favicon.ico",
        faviconSize: "32x32",
        logo: "/assets/login/diya-logo.svg",
        leftImage: "/assets/login/loginLeftImage.svg",
        appName: "Diya AI",
        appPageTitle: "Diya",
        onMicrosoft: true,
        onExtension: false,
        helpUrl: "https://diyafinance.com",
    }
};

module.exports = whiteLabelConfig;