export const compareGuidelinesLoadingStatements = [
    "Analyzing 14,000+ guidelines...",
    "Cross-checking lender overlays...",
    "Scanning for the best interest rate scenarios...",
    "Sifting through underwriting policies...",
    "Matching loan scenarios to lender requirements...",
    "Evaluating borrower eligibility criteria...",
    "Checking exceptions across lenders...",
    "Gathering comparative data on DTI limits...",
    "Reviewing credit score thresholds...",
    "Aligning program stipulations across multiple sources...",
    "Filtering lender-specific conditions...",
    "Locating the most suitable loan products...",
    "Confirming permissible loan-to-value ratios...",
    "Extracting critical FICO score details...",
    "Compiling maximum loan amount specs...",
    "Verifying down payment rules...",
    "Scrutinizing income verification steps...",
    "Comparing guidelines from competing lenders...",
    "Inspecting policy nuances for edge cases...",
    "Ensuring compliance with federal regulations...",
    "Cross-referencing self-employment standards...",
    "Summarizing lender-specific eligibility factors...",
    "Aggregating permissible property types...",
    "Aligning guidelines with your financial details...",
    "Double-checking occupancy requirements...",
    "Correlating appraisal requirements with loan type...",
    "Evaluating credit event seasoning periods...",
    "Gathering updated mortgage insurance details...",
    "Spotting unique guideline exceptions...",
    "Reviewing property condition stipulations...",
    "Synthesizing multiple data sources...",
    "Checking updated interest rate add-ons...",
    "Exploring potential cost-saving options...",
    "Refining insights for specialized loan programs...",
    "Culling outdated or conflicting guidelines...",
    "Cross-verifying adjustable-rate policies...",
    "Pinpointing exact documentation needs...",
    "Assessing lender appetite for risk levels...",
    "Reviewing combined loan-to-value constraints...",
    "Parsing unique jumbo loan requirements...",
    "Consolidating the final recommendation set...",
    "Polishing results for guideline clarity...",
    "Investigating specialized refi programs...",
    "Finalizing loan scenario alignment...",
    "Probing lender overlays for hidden constraints...",
    "Combining knowledge for a concise answer...",
    "Navigating unique lender credit overlays...",
    "Summarizing the best matching guidelines...",
    "Preparing custom mortgage guidelines summary...",
    "Confirming all data for accuracy and compliance...",
    "Almost there—final comparison in progress..."
  ];
  