import React, { useEffect, useState, useMemo } from "react";

const MESSSAGES = [
  "Analyzing email content...",
  "Analyzing attachment data...",
  "Extracting information...",
  "Validating extracted data...",
];

const AddyLoading = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => old + 1);
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const message = useMemo(() => {
    return MESSSAGES[Math.min(count, MESSSAGES.length - 1)];
  }, [count]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="loader">{message}</div>
    </div>
  );
};

export default AddyLoading;
