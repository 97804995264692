function getErrorMessage(error) {
  if (!error) return "";
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    return error.response.data.error;
  }
  return error.message;
}

module.exports = {
  getErrorMessage,
};
