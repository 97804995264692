const GlobalVariables = require("./globalVariables");
const whiteLabelConfig = require("./whiteLabelConfig");

class User {
    static getEndpoints() {
        return {
            trainedAssistants: "/api/user/trained-assistants-new",
            defaultAssistant: "/api/user/default-assistant",
        };
    }

    constructor(uid, token, email, clientHostname) {
        this.uid = uid;
        this.token = token;
        this.email = email;
        this.assistants = []; // Initialize the assistants array in the state
        this.clientHostname = clientHostname;
        this.whiteLabel = whiteLabelConfig[clientHostname];
        if (this.email?.includes("assetline.com.au") || this.email?.includes("altx.com.au")) {
            this.whiteLabel = whiteLabelConfig["assetline.addy.so"];
        }
        
        const defaultAssistants = [
            {
                "appID": "addy-default-assistant-c66e0609-371e-49b2-bb4a-5186526b2a9d",
                "appImageURL": "",
                "appName": (this.whiteLabel && this.whiteLabel.appName) ? this.whiteLabel.appName : "Addy AI",
                "appTitle": "Trained to answer mortgage lending questions",
                "appType": "default",
                "creatorName": "Addy AI",
                "description": "Trained to answer mortgage lending questions",
                "traningStatus": "complete",
                "organizationId": "",
                "organizationName": "Default",
                "backgroundResponse": false,
                "publicId": "1123cacb-2789-4531-936c-8c9b8c94b647", // Default public ID for Addy AI
            },
        ];
        this.defaultAssistants = defaultAssistants;
    }

    isValid() {
        return this.uid !== "" && this.token !== "";
    }

    async getAssistants() {
        const API_URL_STAUTS = GlobalVariables.getAPIURL() + User.getEndpoints().trainedAssistants;
        try {
            // eslint-disable-next-line max-len
            const response = await fetch(
              `${API_URL_STAUTS}?uid=${this.uid}&authEmail=${this.email}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${this.token}`,
                },
              }
            );
            const data = await response.json();
            // Combine default assistants with fetched assistants
            // this.assistants = this.defaultAssistants.concat(data.assistants);
            let returnedAssistants = data.assistants;
            
            if (this.whiteLabel) {
                // Filter returned assistants to remove all assistants with appID prefix "addy-default-assistant"
                returnedAssistants = returnedAssistants.filter((assistant) => {
                    return !assistant.appID.startsWith("addy-default-assistant");
                });
                const fannieMaeId = "addy-default-assistant-2d7053ab-1674-4e26-9660-ed2c46c6241d";
                const freddieMacId = "addy-default-assistant-d17766d1-d0b3-4f4e-b04e-6564c5d334d4";

                if (this.whiteLabel?.theme === "nouri") {
                    // Append Fannie Mae and Freddie Mac assistants to the returned assistants
                    const fannieMaeAssistant = data.assistants.find((assistant) => assistant.appID === fannieMaeId);
                    const freddieMacAssistant = data.assistants.find((assistant) => assistant.appID === freddieMacId);
                    if (fannieMaeAssistant) returnedAssistants.push(fannieMaeAssistant);
                    if (freddieMacAssistant) returnedAssistants.push(freddieMacAssistant);
                }
            }
            this.assistants = returnedAssistants
            return this.assistants;
        } catch (error) {
            console.error("Error fetching assistants:", error);
            return this.defaultAssistants;
        }
    }

    async setDefaultAssistant(appID) {
        // Make a POST request to the API to set the default assistant
        
        const API_URL_STAUTS = GlobalVariables.getAPIURL() + User.getEndpoints().defaultAssistant;
        try {
            if (!this.uid) return true; // If no user logged in, it's fine, don't update
            if (!appID) throw new Error("No appID provided");

            const response = await fetch(API_URL_STAUTS,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.token}`,
                    },
                    body: JSON.stringify({
                        uid: this.uid,
                        authEmail: this.email,
                        appID: appID,
                    }),
                });
            const data = await response.json();
            // console.log("Default assistant set:", data);
            return data?.success;
        } catch (error) {
            console.error("Error setting default assistant:", error);
            return false;
        }
    }

    async getDefaultAssistant() {
        // Make a GET request to the API to get the default assistant
        const API_URL_STAUTS = GlobalVariables.getAPIURL() + User.getEndpoints().defaultAssistant;
        try {
            const response = await fetch(`${API_URL_STAUTS}?uid=${this.uid}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            });
            const data = await response.json();
            const defaultAssistant = data.defaultAssistant;
            return defaultAssistant;
        } catch (error) {
            console.error("Error fetching default assistant:", error);
            return false;
        }
    }
}

module.exports = User;
