/* eslint-disable max-len */
const { default: axios } = require("axios");
const GlobalVariables = require("./globalVariables"); // Import the necessary module

class ChatModel {
  static getEndpoints() {
    return {
      chat: "/chat/chat/chat",
    };
  }

  static async chat(
    email,
    name,
    emailThread,
    selectedTable,
    attachmentListBase64,
    dealsPipeline
  ) {
    const url = GlobalVariables.getAPIURL() + ChatModel.getEndpoints().chat;
    const response = await axios.post(
      url,
      {
        email: email,
        name: name,
        emailThread: emailThread,
        selectedTable: selectedTable,
        attachmentList: attachmentListBase64,
        dealsPipeline: dealsPipeline,
        isExtractingLoanDetails: true,
      },
      {
        timeout: 30000,
      }
    );
    if (response.data.success) {
      return { success: true, chatResponse: response.data.chatResponse };
    } else {
      throw new Error(`Request returned an error`);
    }
  }
}

module.exports = ChatModel;
