import { useState, useEffect, useRef } from "react";
import "./MessageText.css";
// import parse from "html-react-parser";
import { sanitize } from "dompurify";
import { marked } from "marked";
import { v4 as uuidv4 } from "uuid";
import AddyWebSocket from "../models/addyWebSocket";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ComparisonModal from "./ComparisonModal";
import { Skeleton } from "@mui/material";
// Get current window width

function shuffleArray(array) {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

const MessageText = (props) => {
  // const [likeSrc, setLikeSrc] = useState("/thumb.svg");
  //   const [dislikeSrc, setDislikeSrc] = useState("/thumb-1.svg");
  const copyIconRef = useRef(null);
  const feedbackContainerRef = useRef(null);
  // const [messageText, setMessageText] = useState(props.message.message);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMoreSources, setShowMoreSources] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTooltipIndex, setOpenTooltipIndex] = useState(null);
  const [expandedSourceIndexes, setExpandedSourceIndexes] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set up the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      // Remove the resize event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const message = props.message;
  const documents = props.documents;
  const documentsCompared = props.documentsCompared;
  const clientID = props.clientID;
  const isLastMessage = props.isLastMessage;
  const isFirstMessage = props.isFirstMessage;
  const loadingDocuments = props.loadingDocuments;
  const thinkingStatements = props.thinkingStatements
    ? props.thinkingStatements
    : ["Thinking..."];

  // Create a shuffled array of indexes for the thinking statements
  const [shuffledIndexes, setShuffledIndexes] = useState(
    shuffleArray([...Array(thinkingStatements.length).keys()])
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  // Pick a random statement from the shuffled array
  useEffect(() => {
    let interval;
    if (message.action === "thinking" && shuffledIndexes.length > 1 &&
        props.compareMode && props.compareMode === true && (message.type === "assistant")
        && message?.message?.length === 0
    ) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          // If we've shown all statements, re-shuffle and reset to 0
          if (nextIndex >= shuffledIndexes.length) {
            setShuffledIndexes(
              shuffleArray([...Array(thinkingStatements.length).keys()])
            );
            return 0;
          }
          return nextIndex;
        });
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [message.action, message.message, shuffledIndexes]);

  const displayedThinkingMessage =
    thinkingStatements[shuffledIndexes[currentIndex]];

  // const handleLikeClick = () => {
  //     setLikeSrc("/thumbs-up-filled.png");
  //     setDislikeSrc("/thumb-1.svg");
  // };

  const handleDislikeClick = () => {
    setDislikeSrc("/thumbs-down-filled.png");
    // setLikeSrc("/thumb.svg");
  };

  const getMarkedDownText = (markdownText) => {
    const rawMarkup = marked(markdownText, { sanitize: true });

    // Here marked() may have converted \n into \\n
    // so we replace \\n with <br />
    const lineBreaksAdded = rawMarkup.replace(
      /\\n/g,
      "<br class='addy-line-break' />"
    );

    // Purify the raw markup to prevent XSS attacks
    const purifiedMarkup = sanitize(lineBreaksAdded);
    return { __html: purifiedMarkup };
  };

  const handleCopyClick = () => {
    // eslint-disable-next-line no-undef
    copyIconRef.current.src = "/check_stroke.svg";
    setTimeout(() => {
      copyIconRef.current.src = "/copy_stroke.svg";
    }, 5000);
    // Send copy action to client via WebSocket
    // Define the message as a JSON string because the
    // server expects a string.
    // Use JSON.parse to convert it back to an object on client side.
    // IMPORTANT: Please ensure that the message is a valid JSON string.
    const copiedText = message?.message?.replace(/\\n/g, "\n");
    AddyWebSocket.sendDataToWebsocket(
      copiedText,
      `${clientID}-webpage`,
      "copy",
      3
    );

    // Copy the text to the clipboard
    navigator.clipboard.writeText(copiedText).then(
      function () {
        // console.log("Text copied to clipboard");
      },
      function (err) {
        console.error("Error copying text to clipboard: ", err);
      }
    );
  };

  const handleInsertClick = () => {
    // console.log("Insert clicked");
    // Send insert action to client via WebSocket
    const copiedText = message?.message?.replace(/\\n/g, "\n");
    AddyWebSocket.sendDataToWebsocket(
      copiedText,
      `${clientID}-webpage`,
      "insertTextInReplyEditor"
    );
  };

  const isValidURL = (str) => {
    try {
      new URL(str);
    } catch (_) {
      return false;
    }
    return true;
  };

  const toggleExpand = (index) => {
    setExpandedSourceIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Append 'http://' if protocol not present
  const appendHttp = (url) => {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "http://" + url;
    }
    return url;
  };

  const getFaviconURL = (websiteURL) => {
    if (!websiteURL) {
      return "";
    }
    try {
      const urlObject = new URL(websiteURL);
      // Fetch url
      return `https://www.google.com/s2/favicons?domain=${urlObject.hostname}`;
    } catch (_) {
      return "";
    }
  };

  const sourceComponent = (documentTitle, documentContent, referenceNumber) => {
    // Check if the document title is a URL or not
    let isDocumentTitleAURL = documentTitle ? true : false;
    if (documentTitle && !isValidURL(documentTitle)) {
      const testAppendProtocol = appendHttp(documentTitle);
      if (!isValidURL(testAppendProtocol)) {
        // Still not a url
        isDocumentTitleAURL = false;
      }
    }
    return (
      <div
        className="source-container"
        onClick={() => {
          windowWidth > 850 &&
            isDocumentTitleAURL &&
            window.open(documentTitle, "_blank");
        }}
      >
        <div className="source-container-header">
          {/* The favicon if available */}
          {windowWidth > 850 &&
            isDocumentTitleAURL &&
            getFaviconURL(documentTitle) && (
              <img
                className="source-website-favicon"
                src={getFaviconURL(documentTitle)}
                alt=""
              />
            )}

          {/* {windowWidth > 850 && (
       
          )} */}
          <div className="source-container-link">{documentTitle}</div>
          {/* {windowWidth > 850 && (
            <div className="source-delimiter-symbol">&nbsp;•&nbsp;</div>
          )}

          <div className="source-reference-number">{referenceNumber}</div> */}
        </div>

        {/* <div className="source-content-text">
            {documentContent}
        </div> */}
      </div>
    );
  };

  // const sourceTooltipComponent = (context, referenceNumber) => {
  //   // Check if the document title is a URL or not
  //   const documentTitle = context?.title;

  //   let isDocumentTitleAURL = documentTitle ? true : false;
  //   if (documentTitle && !isValidURL(documentTitle)) {
  //     const testAppendProtocol = appendHttp(documentTitle);
  //     if (!isValidURL(testAppendProtocol)) {
  //       // Still not a url
  //       isDocumentTitleAURL = false;
  //     }
  //   }

  //   const formatTextWithLineBreaks = (text) => {
  //     if(!text){
  //       return "";
  //     }
  //     return text.split("\\n").map((line, index) => (
  //       <span key={index}>
  //         {line}
  //         <br />
  //       </span>
  //     ));
  //   }

  //   return (
  //     <div className="source-container-tooltip">
  //       <div className="source-container-header">
  //         {/* The favicon if available */}
  //         {isDocumentTitleAURL && getFaviconURL(documentTitle) && (
  //           <img
  //             className="source-website-favicon"
  //             src={getFaviconURL(documentTitle)}
  //             alt=""
  //           />
  //         )}

  //         {documentTitle && (
  //           <div
  //             className="tooltip-title"
  //           >
  //             {documentTitle}
  //           </div>
  //         )}

  //         {/* {documentTitle && <div className="source-delimiter-symbol" style={{
  //           color: "rgba(255, 255, 255, 1)"
  //         }}>&nbsp;•&nbsp;</div>}

  //         <div className="source-reference-number" style={{
  //           color: "rgba(255, 255, 255, 1)"
  //         }}>
  //         {referenceNumber}
  //         </div> */}
  //       </div>

  //       <div
  //         className="source-content-text-tooltip"
  //       >
  //         {context.text ? formatTextWithLineBreaks(context.text) : ""}
  //         <br></br>
  //         {context.fullText && (
  //           <Tooltip
  //             key={1}
  //             title={formatTextWithLineBreaks(context.fullText)}
  //             arrow
  //             slotProps={{
  //               tooltip: {
  //                 sx: {
  //                   color: "white",
  //                   borderWidth: "2px",
  //                   borderColor: "white",
  //                   backgroundColor: "black",
  //                   maxHeight: "100px",
  //                   scrollBehavior: "smooth",
  //                   overflowY: "auto",
  //                 },
  //               },
  //             }}
  //           >
  //             <div className="source-full-text-tooltip">Read more</div>
  //           </Tooltip>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  const CustomTooltip = styled(({ className, open, ...props }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      open={open}
      onClose={() => setOpenTooltipIndex(null)}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "#333",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      fontSize: "14px",
      borderRadius: "8px",
      padding: "8px 16px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#ffffff",
    },
  }));

  const sourceCard = ({ title, text, sources, reasoning }) => {
    return (
      <div className="comparison-card">
        <dev className="comparison-title">{title}</dev>
        <div className="comparison-content">{text}</div>

        {sources && sources.length > 0 && (
          <div>
            <div className="comparison-index">Sources</div>
            <div className="source-buttons">
              {sources.map((source, index) => (
                <CustomTooltip
                  title={sourceTooltipComponent(source, index + 1)}
                >
                  <div className="source-button">{index + 1}</div>
                </CustomTooltip>
              ))}
            </div>
          </div>
        )}

        {/* {reasoning && (
          <div>
            <div className="comparison-index">Reasoning</div>
            <div className="source-buttons">
              <CustomTooltip
                title={comparisonTooltipComponent(
                  { text: reasoning, fullText: reasoning },
                  1
                )}
              >
                <div className="source-button">{1}</div>
              </CustomTooltip>
            </div>
          </div>
        )} */}
      </div>
    );
  };

  const sourceTooltipComponent = (source, index) => {
    return (
      <div className="tooltip-content">
        <img
          className="close-tooltip-icon"
          onClick={() => setOpenTooltipIndex(null)}
          loading="lazy"
          alt=""
          src="/close.svg"
        />
        <div className="tooltip-title">{source.title}</div>
        <div className="tooltip-text">
          {expandedSourceIndexes[index] ? source.fullText : source.text}
        </div>
        <div
          className="tooltip-footer"
          onClick={(e) => {
            e.stopPropagation();
            toggleExpand(index);
          }}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {expandedSourceIndexes[index] ? "View less" : "View more"}
        </div>
      </div>
    );
  };

  const ComparisonCard = ({ title, text, sources, reasoning, path }) => {
    return (
      <div className="comparison-card">
        {path && path.split(" > ").length > 1 ? (
          <CustomTooltip title={path}>
            <div className="comparison-title">
              {(() => {
                const pathSegments = path
                  .split(" > ")
                  .map((segment) => segment.trim());
                const targetIndex = pathSegments[1]
                  ?.toLowerCase()
                  .includes("mortgage insurers")
                  ? 2
                  : 1;
                return pathSegments[targetIndex]
                  ? pathSegments[targetIndex].charAt(0).toUpperCase() +
                      pathSegments[targetIndex].slice(1)
                  : title;
              })()}
            </div>
          </CustomTooltip>
        ) : (
          <div className="comparison-title">{title}</div>
        )}

        <div className="comparison-content">{text}</div>

        {sources && sources.length > 0 && (
          <div>
            <div className="comparison-index">Sources</div>
            <div className="source-buttons">
              {sources.map((source, index) => (
                <CustomTooltip
                  title={comparisonTooltipComponent(source, title)}
                >
                  <div className="source-button">{index + 1}</div>
                </CustomTooltip>
              ))}
            </div>
          </div>
        )}

        {/* {reasoning && (
            <div className="comparison-section">
              <div className="comparison-index">Reasoning</div>
              <div className="source-buttons">
                <CustomTooltip
                  title={comparisonTooltipComponent(reasoning, title)}
                >
                  <div className="source-button">1</div>
                </CustomTooltip>
              </div>
            </div>
          )} */}
      </div>
    );
  };

  const comparisonTooltipComponent = (source, index) => {
    return (
      <div className="tooltip-content">

        <div className="tooltip-title">
          {index}
        </div>
        <div className="tooltip-text">
          {source.text}
        </div>

        <div className="tooltip-footer">
          <a href={source.url} target="_blank" rel="noopener noreferrer">
            View source
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // console.log("i am here in feedback message", feedbackContainerRef.current);
    if (feedbackContainerRef.current && !isLastMessage) {
      //   console.log("i am here in feedback message 222");
      feedbackContainerRef.current.addEventListener("mouseenter", () => {
        // console.log("i am here in mouse enter");
        feedbackContainerRef.current.classList.add("show-message-actions");
      });
      feedbackContainerRef.current.addEventListener("mouseleave", () => {
        feedbackContainerRef.current.classList.remove("show-message-actions");
      });
    }
    return () => {
      if (feedbackContainerRef.current) {
        feedbackContainerRef.current.removeEventListener(
          "mouseenter",
          () => {}
        );
        feedbackContainerRef.current.removeEventListener(
          "mouseleave",
          () => {}
        );
      }
    };
  }, [isLastMessage]);

  return (
    <section
      className={`top-bar-new-message5 ${
        message.type == "user"
          ? "user-message"
          : message.type == "assistant"
          ? "assistant-message"
          : ""
      } ${isFirstMessage ? "first-message" : ""} ${
        isLastMessage ? "last-message" : ""
      }`}
      id={message.messageId ? message.messageId : uuidv4()}
    >
      <div className="text-field5">
        <div className="h-heading-group">
          <div className="scroll-bar5">
            <div className="image-placeholder1">
              <div className="wrapper-union">
                <img
                  className="union-icon"
                  loading="lazy"
                  alt={`${message.type}`}
                  src={`${message.imageURL}`}
                />
              </div>
            </div>

            <div className="frame-parent1">
              <div className="h2-heading-parent24">
                <b className="h2-heading49">
                  {message.name && message.name.length > 40
                    ? `${message.name.substring(0, 39)}...`
                    : message.name}
                </b>
                {message.type === "user" && message.selectedText && (
                  <div className="selected-text-parent">
                    <div className="selected-text-header-parent">
                      <div className="selected-text-header">
                        {message.selectedTextTitle || "Selected text"}
                      </div>
                    </div>
                    <div className="selected-text">
                      {message.selectedText.length > 250
                        ? message.selectedText.substring(0, 250) + "..."
                        : message.selectedText}
                    </div>
                  </div>
                )}
                {documentsCompared && documentsCompared.length > 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    <div className="comparison-list">
                      <div className="comparison-list-front">
                        <img
                          className="addy-check-security tooltip-1"
                          width="16"
                          height="16"
                          src="/book.png"
                        />
                        <div className="comparison-title">
                          Investor Guidelines Comparison
                        </div>
                      </div>
                      <div
                        className="comparison-list-end"
                        onClick={() => setOpenDialog(true)}
                      >
                        <div className="comparison-number">
                          {documentsCompared.length - 3 > 0 &&
                            (documentsCompared.length - 3 < 5
                              ? `+${documentsCompared.length - 3}`
                              : `5+`)}
                        </div>
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          View all
                        </div>
                      </div>
                    </div>
                    <div className="sources-comparison-list addy-custom-scroll">

                      {documentsCompared.map((context, index) => (
                        (index < 3 && 
                          <ComparisonCard
                            title = {context.title}
                            text = {context.text}
                            sources = {context.source}
                            reasoning = {context.reasoning}
                            path = {context.filePath}
                          />
                        ) 
                      ))}
                    </div>
                  </div>
                )}
                {documents && documents.length > 0 ? (
                  <div className="sources-parent-div">
                    <div className="sources-title">Sources</div>
                    <div className="sources-list addy-custom-scroll">
                      {documents.map((context, index) =>
                        windowWidth > 850 ? (
                          <>
                            <CustomTooltip
                              key={index}
                              title={sourceTooltipComponent(context, index + 1)}
                              open={openTooltipIndex === index}
                            >
                              <div
                                onMouseEnter={() => setOpenTooltipIndex(index)}
                              >
                                {(index + 1 <= 3 || showMoreSources) &&
                                  sourceComponent(
                                    context.title,
                                    context.text,
                                    index + 1
                                  )}
                              </div>
                            </CustomTooltip>
                          </>
                        ) : (
                          <>
                            <CustomTooltip
                              key={index}
                              title={sourceTooltipComponent(context, index + 1)}
                              open={openTooltipIndex === index}
                            >
                              <div
                                onClick={() => {
                                  setOpenTooltipIndex((prev) =>
                                    prev === index ? null : index
                                  );
                                }}
                              >
                                {(index + 1 <= 3 || showMoreSources) &&
                                  sourceComponent(
                                    context.title,
                                    context.text,
                                    index + 1
                                  )}
                              </div>
                            </CustomTooltip>
                          </>
                        )
                      )}
                    </div>
                    {documents && documents.length > 3 && windowWidth > 850 && (
                      <div
                        className="show-more-sources-text"
                        onClick={() => {
                          setShowMoreSources(!showMoreSources);
                        }}
                      >
                        {showMoreSources
                          ? `Hide ${documents.length - 3} sources`
                          : `Show ${documents.length - 3} more`}
                      </div>
                    )}
                  </div>
                ) : (
                  isLastMessage &&
                  loadingDocuments &&
                  message.type !== "user" && (
                    <div>
                      <div className="sources-title">Sources</div>
                      <div
                        className="sources-list addy-custom-scroll"
                        style={{ marginBottom: "20px" }}
                      >
                        <Skeleton variant="rounded" width={300} height={37} />
                        <Skeleton variant="rounded" width={300} height={37} />
                        <Skeleton variant="rounded" width={300} height={37} />
                      </div>
                    </div>
                  )
                )}

                <div className="h2-heading50">
                  <span>
                  {message.action == "thinking" && (
                    props.compareMode ? 
                        <div className="thinking-text">
                            {displayedThinkingMessage}
                        </div> : 
                        <div className="dot"></div>
                    )}

                    {/* eslint-disable-next-line max-len */}
                    {/* <div className={`model-response-text ${message.action == "thinking" ? "hide" : ""}`}>
                                            {parse(message.message)}
                                        </div> */}
                    <div
                      className={`model-response-text ${
                        message.action == "thinking" ? "hide" : ""
                      }`}
                      dangerouslySetInnerHTML={getMarkedDownText(
                        message.message
                      )}
                    />
                  </span>
                </div>
              </div>
              {message.type === "assistant" && (
                <div
                  ref={feedbackContainerRef}
                  className={`feedback-container frame-parent2 ${
                    message.action == "thinking" && !message?.message?.length
                      ? "hide"
                      : ""
                  } ${isLastMessage ? "show-message-actions" : ""}`}
                >
                  <div className="addy-copy-response-div">
                    <div
                      className="copy-response thumb-group"
                      onClick={handleInsertClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="insert"
                        src="/insert_stroke.svg"
                        data-toggle="tooltip"
                        title="Insert in email editor"
                      />
                      <div className="h2-heading52">Insert</div>
                    </div>

                    <div
                      className="copy-response thumb-group"
                      onClick={handleCopyClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="copy"
                        src="/copy_stroke.svg"
                        data-toggle="tooltip"
                        title="Copy to clipboard"
                        ref={copyIconRef}
                      />
                      <div className="h2-heading52">Copy</div>
                    </div>
                    <div
                      className="copy-response thumb-group"
                      onClick={props.onRephraseButtonClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="rephrase"
                        src="/rephrase.svg"
                        f
                        data-toggle="tooltip"
                        title="Rephrase the provided email"
                      />
                      <div className="h2-heading52">Rephrase</div>
                    </div>
                  </div>

                  <div className="addy-rate-response-div">
                    {/* <div className="thumb-parent">
                                            <img
                                                data-toggle="tooltip"
                                                title="Good response"
                                                className="like-thumb thumb-icon"
                                                loading="lazy"
                                                alt=""
                                                src={likeSrc}
                                                onClick={handleLikeClick}
                                            />
                                            <div className="h2-heading51"></div>
                                        </div> */}
                    {/* <div className="thumb-parent">
                      <img
                        data-toggle="tooltip"
                        title="Bad response"
                        className="dislike-thumb thumb-icon"
                        alt=""
                        src={dislikeSrc}
                        onClick={handleDislikeClick}
                      />
                      <div className="h2-heading52"></div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ComparisonModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        documentsCompared={documentsCompared}
        message={message}
        clientID={clientID}
      />
    </section>
  );
};

export default MessageText;
