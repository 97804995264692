/* eslint-disable max-len */
/* eslint-disable indent */
import "./ChatAssistantDropdown2.css";
import { useEffect, useRef, forwardRef, useState } from "react";
import { Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import GlobalVariables from "../models/globalVariables";

const ChatAssistantDropdown2 = forwardRef((props, ref) => {
  // Get props
  const referencedElementId = props.referencedElementId;
  const displayPosition = props.displayPosition;
  const smallContainer = props?.smallContainer || false;
  const marginRightV = props?.marginRight || "";

  const assistants =
    props.assistants && props.assistants.length
      ? props.assistants.map((assistant) => {
          if (assistant && assistant.appName)
            assistant["name"] = assistant.appName;
          if (assistant && assistant.appTitle)
            assistant["title"] = assistant.appTitle;
          if (assistant && !assistant.appID && assistant["name"])
            assistant["appID"] = assistant["name"];
          return assistant;
        })
      : [];
  const selectedAssistant = props.selectedAssistant;
  if (
    selectedAssistant &&
    !selectedAssistant.appID &&
    selectedAssistant["name"]
  )
    selectedAssistant["appID"] = selectedAssistant["name"];

  const defaultAssistant = props.defaultAssistant;
  const hideTabs = props.hideTabs;
  const hideAvatar = props.hideAvatar;

  const assistantsDropdown = useRef(null);
  const [selectedTab, setSelectedTab] = useState("all");
  const sampleEmojis = ["🧑🏼‍⚕️", "👨🏽‍💼", "👩🏼‍💼", "👨🏼‍💼", "🧑🏽‍💼"];

  // Function to display dropdown under the referenced element
  const displayDropdownUnder = () => {
    // eslint-disable-next-line no-undef
    const refElement = document.getElementById(referencedElementId);
    const dropdown = assistantsDropdown.current;

    if (refElement && dropdown) {
      const rect = refElement.getBoundingClientRect();
      let top = rect.bottom;
      const left = 0;

      // Modify top value based on displayPosition
      if (displayPosition === "top") {
        top = rect.top - dropdown.offsetHeight;
      }

      dropdown.style.position = "absolute";
      dropdown.style.top = `${top + 5}px`;
      if (marginRightV === "") {
        dropdown.style.left = `${left}px`;
        dropdown.style.right = ``;
      } else {
        dropdown.style.left = ``;
        dropdown.style.right = `${left}px`;
      }
    }
  };

  const redirectToNewAssistant = () => {
    const HOSTNAME = window.location.hostname;
    if (HOSTNAME === "devmail.addy.so" || HOSTNAME === "mail.addy.so") {
      window.open(`${HOSTNAME}/aiassistants`, "_blank");
    } else {
      window.open("https://app.addy.so/aiassistants", "_blank");
    }
  };

  // const modifyAssistantsFormat = (assistants) => {
  //     // Modify the assistant object to include the required fields for display
  //     const modifiedAssistants = assistants.map((assistant) => {
  //         if (assistant && assistant.appName) assistant["name"] = assistant.appName;
  //         if (assistant && assistant.appTitle) assistant["title"] = assistant.appTitle;
  //         return assistant;
  //     });
  //     return modifiedAssistants;
  // };

  // Call the displayDropdownUnder function when the component mounts
  useEffect(() => {
    displayDropdownUnder();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    displayDropdownUnder();
  }, [props]); // Reload component on selectedTab change
  useEffect(() => {}, [defaultAssistant]); // Reload component on defaultAssistant change

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const onAssistantClick = (assistant) => {
    props.onAssistantClick(assistant);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <section ref={assistantsDropdown} className="frame-section">
      <div className="text-field4">
        <div className="assistants-container" ref={ref}>
          <div className="assistants">
            <img className="plus-icon5" alt="" src="/plus.svg" />
            <div className="chat-with-your-assistants1">
              <div className="h2-heading29">{props.headerText}</div>
              <div className="create-new-wrapper">
                <p
                  onClick={() => redirectToNewAssistant()}
                  href={`${props.createNewLink ? props.createNewLink : ""}`}
                  target="_blank"
                  className="create-new"
                >
                  + Create New
                </p>
              </div>
            </div>
            <div className="frame-parent">
              {!hideTabs && (
                <div className="heading-parent">
                  <div className="heading3">
                    <div
                      className={`heading4 ${
                        smallContainer ? "heading4-small" : ""
                      }`}
                    >
                      <div
                        className={`heading5 ${
                          selectedTab === "all" ? "active" : ""
                        }`}
                      >
                        <div
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("all")}
                        >
                          All
                        </div>
                      </div>
                      <div
                        className={`heading5 ${
                          selectedTab === "default" ? "active" : ""
                        }`}
                      >
                        <div
                          data-toggle="tooltip"
                          title="Free assistants provided by Addy"
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("default")}
                        >
                          Free
                        </div>
                      </div>

                      <div
                        className={`heading5 ${
                          selectedTab === "enterprise" ? "active" : ""
                        }`}
                      >
                        <div
                          data-toggle="tooltip"
                          title="Custom assistants created by you or your organization"
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("enterprise")}
                        >
                          Enterprise
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <img
                                    className="search-icon"
                                    loading="lazy"
                                    alt=""
                                    src="/search.svg"
                                /> */}
                </div>
              )}
              <div className="item-parent addy-simple-vertical-scrollbar">
                {selectedTab === "all" &&
                  assistants.map((assistant, index) => (
                    <div
                      style={{
                        display: "flex",
                      }}
                      className={`item1-wrapper ${
                        selectedAssistant &&
                        assistant?.appID === selectedAssistant?.appID
                          ? "selected"
                          : ""
                      }`}
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        onAssistantClick(assistant);
                      }}
                    >
                      <img
                        className="star-image"
                        width="32"
                        height="32"
                        style={{
                          width: 32,
                          height: 32,
                          borderRadius: "100%",
                          marginTop: 4,
                        }}
                        src={
                          assistant?.imageUrl ||
                          "https://i.imgur.com/9VBT3XI.png"
                        }
                      />
                      <div className="item1">
                        <div
                          className={`frame-group ${
                            smallContainer ? "frame-group-small" : ""
                          }`}
                        >
                          <div className="frame-wrapper">
                            <div className="h2-heading-parent16">
                              {/* pick random emoji  */}
                              {!hideAvatar && (
                                <h2 className="h2-heading36">
                                  {
                                    sampleEmojis[
                                      Math.floor(
                                        Math.random() * sampleEmojis.length
                                      )
                                    ]
                                  }
                                </h2>
                              )}
                              <b className="addy-assistant-name h2-heading37">
                                {assistant.name.length > 16
                                  ? `${capitalizeFirstLetter(
                                      assistant.name.substring(0, 14)
                                    )}...`
                                  : capitalizeFirstLetter(assistant.name)}
                              </b>
                            </div>
                          </div>
                          {assistant.organizationName && (
                            <div
                              className={`infinite-shelf-container ${
                                smallContainer
                                  ? "infinite-shelf-container-small"
                                  : ""
                              }`}
                            >
                              <div className="infinite-shelf">
                                {assistant.organizationName.length > 11
                                  ? `${assistant.organizationName.substring(
                                      0,
                                      10
                                    )}...`
                                  : assistant.organizationName}
                              </div>
                            </div>
                          )}
                        </div>
                        {assistant.title && (
                          <div className="it-helps-you-answer-clients-q-parent">
                            <div className="it-helps-you">
                              <div className="item-row2">
                                <div className="it-helps-you item-description">
                                  {assistant.title}
                                </div>

                                <Tooltip
                                  key="make-default-assistant-all"
                                  title="Make default assistant"
                                  arrow={false}
                                >
                                  <img
                                    className="star-image"
                                    width="15"
                                    height="15"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      props.updateDefaultAssistant(
                                        assistant,
                                        e
                                      );
                                    }}
                                    src={
                                      assistant === defaultAssistant
                                        ? "/star-yellow-filled.png"
                                        : "/star-yellow-outline.png"
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}

                {assistants.length === 0 && (
                  <div className="loading-text">No assistants found</div>
                )}
                {!hideTabs &&
                  selectedTab === "default" &&
                  assistants
                    .filter(
                      (assistant) =>
                        assistant.appType.toLowerCase() === "default"
                    )
                    .map((assistant, index) => (
                      <div
                        style={{
                          display: "flex",
                        }}
                        className={`item1-wrapper ${
                          selectedAssistant &&
                          assistant?.appID === selectedAssistant?.appID
                            ? "selected"
                            : ""
                        }`}
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          onAssistantClick(assistant);
                        }}
                      >
                        <img
                          className="star-image"
                          width="32"
                          height="32"
                          style={{
                            width: 32,
                            height: 32,
                            borderRadius: "100%",
                            marginTop: 4,
                          }}
                          src={
                            assistant?.imageUrl ||
                            "https://i.imgur.com/9VBT3XI.png"
                          }
                        />
                        <div
                          className={`item1 ${
                            selectedAssistant &&
                            assistant?.appID === selectedAssistant?.appID
                              ? "selected"
                              : ""
                          }`}
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            onAssistantClick(assistant);
                          }}
                        >
                          <div
                            className={`frame-group ${
                              smallContainer ? "frame-group-small" : ""
                            }`}
                          >
                            <div className="frame-wrapper">
                              <div className="h2-heading-parent16">
                                {!hideAvatar && (
                                  <h2 className="h2-heading36">
                                    {
                                      sampleEmojis[
                                        Math.floor(
                                          Math.random() * sampleEmojis.length
                                        )
                                      ]
                                    }
                                  </h2>
                                )}
                                <b className="addy-assistant-name h2-heading37">
                                  {assistant.name.length > 16
                                    ? `${assistant.name.substring(0, 14)}...`
                                    : assistant.name}
                                </b>
                              </div>
                            </div>
                            {assistant.organizationName && (
                              <div
                                className={`infinite-shelf-container ${
                                  smallContainer
                                    ? "infinite-shelf-container-small"
                                    : ""
                                }`}
                              >
                                <div className="infinite-shelf">
                                  {assistant.organizationName.length > 11
                                    ? `${assistant.organizationName.substring(
                                        0,
                                        10
                                      )}...`
                                    : assistant.organizationName}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="it-helps-you-answer-clients-q-parent">
                            <div className="item-row2">
                              <div className="it-helps-you item-description">
                                {assistant.title}
                              </div>
                              <Tooltip
                                key="make-default-assistant-default"
                                title="Make default assistant"
                                arrow={false}
                              >
                                <img
                                  className="star-image"
                                  width="15"
                                  height="15"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.updateDefaultAssistant(assistant, e);
                                  }}
                                  src={
                                    assistant === defaultAssistant
                                      ? "/star-yellow-filled.png"
                                      : "/star-yellow-outline.png"
                                  }
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                {!hideTabs &&
                  selectedTab === "enterprise" &&
                  assistants
                    .filter(
                      (assistant) =>
                        assistant.appType.toLowerCase() !== "default"
                    )
                    .map((assistant, index) => (
                      <div
                        style={{
                          display: "flex",
                        }}
                        className={`item1-wrapper ${
                          selectedAssistant &&
                          assistant?.appID === selectedAssistant?.appID
                            ? "selected"
                            : ""
                        }`}
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          onAssistantClick(assistant);
                        }}
                      >
                        <img
                          className="star-image"
                          width="32"
                          height="32"
                          style={{
                            width: 32,
                            height: 32,
                            borderRadius: "100%",
                            marginTop: 4,
                          }}
                          src={
                            assistant?.imageUrl ||
                            "https://i.imgur.com/9VBT3XI.png"
                          }
                        />
                        <div
                          className={`item1 ${
                            selectedAssistant &&
                            assistant?.appID === selectedAssistant?.appID
                              ? "selected"
                              : ""
                          }`}
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            onAssistantClick(assistant);
                          }}
                        >
                          <div
                            className={`frame-group ${
                              smallContainer ? "frame-group-small" : ""
                            }`}
                          >
                            <div className="frame-wrapper">
                              <div className="h2-heading-parent16">
                                {!hideAvatar && (
                                  <h2 className="addy-assistant-name h2-heading36">
                                    {
                                      sampleEmojis[
                                        Math.floor(
                                          Math.random() * sampleEmojis.length
                                        )
                                      ]
                                    }
                                  </h2>
                                )}
                                <b className="addy-assistant-name h2-heading37">
                                  {assistant.name.length > 16
                                    ? `${assistant.name.substring(0, 14)}...`
                                    : assistant.name}
                                </b>
                              </div>
                            </div>
                            {assistant.organizationName && (
                              <div
                                className={`infinite-shelf-container ${
                                  smallContainer
                                    ? "infinite-shelf-container-small"
                                    : ""
                                }`}
                              >
                                <div className="infinite-shelf">
                                  {assistant.organizationName.length > 11
                                    ? `${assistant.organizationName.substring(
                                        0,
                                        10
                                      )}...`
                                    : assistant.organizationName}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="it-helps-you-answer-clients-q-parent">
                            <div className="it-helps-you">
                              <div className="item-row2">
                                <div className="it-helps-you item-description">
                                  {assistant.title}
                                </div>
                                <Tooltip
                                  key="make-default-assistant-enterprise"
                                  title="Make default assistant"
                                  arrow={false}
                                >
                                  <img
                                    className="star-image"
                                    width="15"
                                    height="15"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      props.updateDefaultAssistant(
                                        assistant,
                                        e
                                      );
                                    }}
                                    src={
                                      assistant === defaultAssistant
                                        ? "/star-yellow-filled.png"
                                        : "/star-yellow-outline.png"
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                {!hideTabs &&
                  selectedTab === "enterprise" &&
                  assistants.filter(
                    (assistant) => assistant.appType.toLowerCase() !== "default"
                  ).length == 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <br />
                      <br />
                      <br />
                      <div className="loading-text">
                        No custom assistants found &nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <div className="create-new-wrapper">
                        <p
                          onClick={() => redirectToNewAssistant()}
                          href={`${
                            props.createNewLink ? props.createNewLink : ""
                          }`}
                          target="_blank"
                          className="create-new"
                        >
                          + Create New
                        </p>
                      </div>
                    </div>
                  )}

                {/* {selectedTab !== "default" &&
                                    <div className="createButtonDiv">
                                        <LoadingButton
                                            onClick={() => redirectToNewAssistant()}
                                            className={`button10`}
                                            startIcon={
                                                <img
                                                    width="18px"
                                                    height="18px"
                                                    background-color="white"
                                                    src="/electric_bolt_AI.svg"
                                                />
                                            }
                                            variant="contained"
                                            sx={{
                                                "&:hover": {
                                                    background:
                                                        "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                                                },
                                                background:
                                                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                                            }}
                                        >
                                            Create
                                        </LoadingButton>
                                    </div>
                                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ChatAssistantDropdown2;
